import React, { FC, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import CreateCredit, { ICreateCreditItem } from '~/shared/create-credit/create-credit.component';
import { Modal } from '~/features/primitives/modal';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import { printEndpoints } from '~/services/auth-endpoints';
import { LocaleOptions, createUrl, endpoints } from '~/services/service-endpoint';
import { Orders as OrderTypes } from '~/models/orders.d';
import ErrorBox from '~/shared/error-box/error-box';
import { ROUTER_QUERY_FUNCTIONS } from '~/constants/query';
import { useLayoutState } from '~/context/layout.context';
import { apiClient } from '~/services/api-client';
import useUser from '~/libs/use-user';

const FtzPlusIntegration: FC = () => {
    const { query } = useRouter();
    const translate = useTranslations();
    const [isOpen, setOpen] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [isError, setError] = useState(false);
    const { selectedDepartment } = useLayoutState();
    const [creditDraft, setCreditDraft] = useState<OrderTypes.ICreditDraftDetails | null>(null);
    const { user } = useUser();

    const fetchData = async (id: string) => {
        const url = createUrl({
            endpoint: `${endpoints.orders.externalCredits}/${id}/details`,
            localeOption: LocaleOptions.path,
            query: {
                departmentId: selectedDepartment?.id as number,
            },
        });
        try {
            const response: OrderTypes.ICreditDraftDetails = await apiClient.auth(user).get(url);
            setCreditDraft(response);
        } catch (error) {
            setError(true);
        } finally {
            setFetching(false);
        }
    };

    const handleSendFeedback = async (creditId?: number) => {
        const status = creditId ? 'complete' : 'abandon';
        const url = createUrl({
            endpoint: `${endpoints.orders.externalCredits}/${query?.draftId}/${status}`,
            localeOption: LocaleOptions.query,
            query: {
                creditId: creditId as number,
                departmentId: selectedDepartment?.id as number,
            },
        });

        await apiClient.auth(user).post(url);

        if (status === 'complete') {
            window.open(`${printEndpoints.return}?ids=sellerOrderNumber${creditId}`, '_blank');
            window.location.href = creditDraft?.successRedirectUrl as string;
        } else {
            window.location.href = creditDraft?.cancelRedirectUrl as string;
        }
    };

    useEffect(() => {
        if (query?.fn === ROUTER_QUERY_FUNCTIONS.externalCredit && query?.draftId) {
            setOpen(true);
            fetchData(query?.draftId as string);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const handleOpenChange = useCallback(
        (open: boolean) => {
            if (open) {
                return;
            }

            handleSendFeedback();
        },
        [handleSendFeedback],
    );

    if (!isOpen) return null;

    return (
        <Modal position="right" open={isOpen} onOpenChange={handleOpenChange} title={translate('overview.createCredit', 'Opret kreditering')}>
            <>
                {fetching && <Loader />}
                {isError && <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>}
                {creditDraft && (
                    <CreateCredit
                        onClose={handleSendFeedback}
                        items={creditDraft?.items as ICreateCreditItem[]}
                        carInfo={creditDraft?.carInfo}
                        invoiceDate={creditDraft?.displayDateRange as string}
                        invoiceId={creditDraft?.invoiceIds?.join(', ') as string}
                        creditDraftDetails={creditDraft}
                    />
                )}
            </>
        </Modal>
    );
};

export default FtzPlusIntegration;
