import { captureException, withScope } from '@sentry/nextjs';
import clsx from 'clsx';
import { useStaticContent } from '~/libs/queries/bff';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLayoutState } from '~/context/layout.context';
import { useLayoutVehicle } from '~/context/use-layout-vehicle';
import useNavigationLayout from '~/context/use-navigation.context';
import { GA4SearchMiniSearch, GA4SearchMiniSearchClick } from '~/libs/ga4';
import { useProductSearch, useProductSearchMutation, useSearchHistory, useVehicleSearch, useVehicleSearchMutation } from '~/libs/queries/search';
import { CmsData } from '~/models/cms-data';
import { Products as ProductTypes } from '~/models/products.d';
import type { Vehicles as VehicleTypes } from '~/models/vehicles';
import useDebounce from '~/shared/hooks/debounce.hook';
import { MediaQuery, useMediaQueryDetect } from '~/shared/media-query-detect';
import InputField from './input-field/input-field.component';
import { formatQuickSearchData, formatVehicleData, isVehicleSearchItem } from './search-root.helpers';
import type { IQuickSearchItem, IVehicleSearchItem } from './search-root.models.d';
import styles from './search-root.module.scss';

const MultipleVehicleDialog = dynamic(() => import('./multiple-vehicles-dialog/multiple-vehicles-dialog.component'));

/*
  License plates:
  bm70049, aj46329, duddi, vinter, CA23462

  K-type:
  17295

  Cross-number:
  1234, 0 986 594 582

  Vin:
  vnkkl963x0a292652, WVWZZZ1HZSB012475, WVWZZZ3CZCE017719, TRUZZZ8P181006016

  Product:
  1001030118B, 90920B !!!, 54799-10, 54EXPTPALU-FE1B, 545361-0053-201
*/

const SearchRoot: FC = () => {
    const { data } = useStaticContent();
    const { vehicle } = useLayoutState();
    const { navState } = useNavigationLayout();
    const { setVehicle, setLicensePlateCountry } = useLayoutVehicle();
    const { history, addHistoryEntry, resetHistory } = useSearchHistory();
    const router = useRouter();
    const [inputValue, setInputValue] = useState(typeof router.query?.query === 'string' ? router.query.query : '');
    const [vehiclesDialogOptions, setVehiclesDialogOptions] = useState<VehicleTypes.ICommonVehicleDetails[]>([]);
    const isMobile = useMediaQueryDetect(MediaQuery.SM);
    const dialogPromiseResolve = useRef<(vehicle: VehicleTypes.ICommonVehicleDetails) => void>();
    const dialogPromiseReject = useRef<(reason: string) => void>();

    const resetLicensePlateCountry = () => {
        const defaultLicensePlateCountry = data?.licensePlateLanguages?.find(({ isDefault }: { isDefault: boolean }) => isDefault);
        if (!defaultLicensePlateCountry?.code) return { next: true };

        setLicensePlateCountry(defaultLicensePlateCountry ? defaultLicensePlateCountry.code : null);
    };

    const debouncedInputValue = useDebounce(inputValue, 300);

    const {
        data: productsData,
        isLoading: isLoadingProductsData,
        error: errorProductsData,
    } = useProductSearch({ vehicle: vehicle as CmsData.IBaseVehicleInfo, input: debouncedInputValue });

    const { mutateAsync: mutateVehicleSearchAsync } = useVehicleSearchMutation({
        vehicle: vehicle as CmsData.IBaseVehicleInfo,
        input: inputValue,
    });

    const {
        data: vehicleData,
        isLoading: isLoadingVehicleData,
        isSuccess: isVehicleDataSuccess,
        error: errorVehicleData,
    } = useVehicleSearch({ vehicle: vehicle as CmsData.IBaseVehicleInfo, input: debouncedInputValue });

    const isVehicleDataIdle = isLoadingVehicleData && !isVehicleDataSuccess;

    const { mutateAsync: mutateProductSearchAsync } = useProductSearchMutation({
        vehicle: vehicle as CmsData.IBaseVehicleInfo,
        input: inputValue,
    });

    useEffect(() => {
        if (!vehicle) {
            resetLicensePlateCountry();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicle]);

    useEffect(() => {
        if (router.query?.query !== inputValue) {
            setInputValue(typeof router.query?.query === 'string' ? router.query.query : '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query]);

    const getSearchUrlWithVehicle = (url: string, _vehicle: CmsData.IBaseVehicleInfo) => {
        if (!(_vehicle?.licensePlate?.number && _vehicle.licensePlate?.country)) return { next: true }; // vehicle information is missing

        const vehicleUrl = new URL(url, window.location.origin);

        _vehicle.carId && vehicleUrl.searchParams.set('carModelTypeId', String(_vehicle.carId));
        _vehicle.licensePlate && vehicleUrl.searchParams.set('licensePlate', _vehicle?.licensePlate?.number);
        _vehicle.licensePlate && vehicleUrl.searchParams.set('licensePlateCountry', _vehicle.licensePlate?.country);
        _vehicle.vin && vehicleUrl.searchParams.set('vin', _vehicle.vin);

        return vehicleUrl.toString();
    };

    // track when a particular term gets any search results
    useEffect(() => {
        if (!productsData) {
            return;
        }

        const { directHits, crossNumbers, sparePartCategories, universalCategories, suggestions } = productsData;

        const suggestionsList = [
            ...(directHits ?? []),
            ...(crossNumbers ?? []),
            ...(sparePartCategories ?? []),
            ...(universalCategories ?? []),
            ...(suggestions ?? []),
        ].filter(Boolean);

        if (suggestionsList.length) {
            GA4SearchMiniSearch(inputValue);
        }
    }, [productsData, inputValue]);

    const completeSearch = ({
        url,
        historyEntry,
    }: {
        url: string;
        historyEntry: IVehicleSearchItem | ProductTypes.IDirectHitQuickSearchResult | ProductTypes.IQuickSearchResult;
    }): void => {
        router.push(url);
        addHistoryEntry({
            ...historyEntry,
            vehicleClass: isVehicleSearchItem(historyEntry) ? historyEntry.vehicleClass : 0,
        });
    };

    const multipleVehicleDialog = (result: VehicleTypes.ICommonVehicleDetails[]) =>
        new Promise<VehicleTypes.ICommonVehicleDetails>((resolve, reject) => {
            // open modal
            setVehiclesDialogOptions(result);

            dialogPromiseResolve.current = resolve;
            dialogPromiseReject.current = reject;
        });

    const doVehicleSearch = async () => {
        try {
            // If Vehicle found go to sparePartsList for that vehicle
            const result = await mutateVehicleSearchAsync();

            if (!result || result.length === 0) {
                return { next: true }; // no result
            }

            let _selectedVehicle: VehicleTypes.ICommonVehicleDetails | void;
            if (result.length > 1) {
                // wait for selection in dialog
                const dialog = await multipleVehicleDialog(result).catch((error) => {
                    captureException(error);
                });
                if (!dialog) return { next: false }; // dismissed

                _selectedVehicle = dialog;
            } else {
                const [first] = result;
                _selectedVehicle = first;
            }

            if (!_selectedVehicle?.sparePartsUrl) return { next: true }; // invalid

            setVehicle({
                carId: _selectedVehicle.modelTypeId?.toString(),
                licensePlate: _selectedVehicle.licensePlate,
                vin: _selectedVehicle.vin,
                vehicleClass: _selectedVehicle.vehicleClass,
            });

            setInputValue('');
            completeSearch({
                url: _selectedVehicle.sparePartsUrl,
                historyEntry: formatVehicleData(_selectedVehicle),
            });

            return { next: false }; // success
        } catch (e) {
            withScope((scope) => {
                scope.setTag('where', 'doVehicleSearch');
                scope.setExtra('details', {
                    message: "Couldn't do vehicle search",
                });
                captureException(e);
            });

            return { next: false };
        }
    };

    const doProductSearch = async () => {
        try {
            // If singe DirectHit got to products
            const result = await mutateProductSearchAsync();

            const hasDirectHit = result?.directHits?.length === 1;

            if (hasDirectHit) {
                const directHit = result?.directHits?.[0];

                if (!directHit?.url) return { next: true }; // no result

                if (isCodeMatchType(directHit as ProductTypes.IDirectHitQuickSearchResult)) {
                    completeSearch({
                        url: directHit?.url,
                        historyEntry: directHit as ProductTypes.IDirectHitQuickSearchResult,
                    });
                    return true;
                }
            }

            if (!result.searchResultPage?.url) return { next: true }; // no result

            const searchResultsPageUrl = vehicle ? getSearchUrlWithVehicle(result.searchResultPage.url, vehicle) : result.searchResultPage?.url;

            const searchItem: ProductTypes.IQuickSearchResult = {
                text: inputValue,
                secondaryText: '',
                type: ProductTypes.QuickSearchResultType.SearchSuggestion,
                url: typeof searchResultsPageUrl === 'string' ? searchResultsPageUrl : undefined,
                isDirectMatch: false,
            };

            if (!searchItem.url) return { next: true }; // invalid

            completeSearch({
                url: searchItem.url,
                historyEntry: searchItem,
            });
            return true;
        } catch (e) {
            withScope((scope) => {
                scope.setTag('where', 'doProductSearch');
                scope.setExtra('details', {
                    message: "Couldn't do product search",
                });
                captureException(e);
            });
        }
    };

    const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        if (inputValue?.length < 2) return;

        GA4SearchMiniSearch(inputValue);

        if (!vehicle) {
            const { next } = await doVehicleSearch();

            if (!next) return;
        }

        return doProductSearch();
    };

    const handleSearchButtonClick = () => {
        if (!inputValue) return;

        handleSubmit();
    };

    const handleSelectItem = async (item: IQuickSearchItem) => {
        // TODO: fix types
        if (!item.url || !item.text) {
            return { next: true };
        }

        completeSearch({
            url: item.url,
            historyEntry: item,
        });

        GA4SearchMiniSearchClick(inputValue, item.secondaryText || 'N/A');

        if (item.type !== 'Vehicle') {
            setInputValue(item.text);
        }
    };

    const handleRemoveSelectedVehicle = () => {
        setVehicle(null);
        resetLicensePlateCountry();

        const url = new URL(router.asPath, window.location.origin);

        if ([...url.searchParams.keys()].some((item) => ['licensePlate', 'licensePlateCountry', 'carModelTypeId'].includes(item))) {
            url.searchParams.delete('licensePlate');
            url.searchParams.delete('licensePlateCountry');
            url.searchParams.delete('carModelTypeId');

            router.replace(url, undefined, {
                shallow: true,
            });
        } else {
            if (!data?.loggedInPageUrl) return { next: true };
            router.push(data?.loggedInPageUrl);
        }
    };

    const quickSearchItemsList =
        inputValue || vehicle
            ? formatQuickSearchData({
                  products: productsData ?? {},
                  vehicles: vehicleData ?? [],
                  isMobile,
              })
            : history;

    const isError = Boolean(
        errorProductsData && !String(errorProductsData).includes('aborted') && errorVehicleData && !String(errorVehicleData).includes('aborted'),
    );

    return (
        <div
            className={clsx(styles.root, {
                [styles.isOpen]: navState.searchIsOpen,
            })}
        >
            {!!vehiclesDialogOptions.length && (
                <MultipleVehicleDialog
                    options={vehiclesDialogOptions}
                    onSelectCallback={(option) => {
                        dialogPromiseResolve.current?.(option);
                        // close modal
                        setVehiclesDialogOptions([]);
                    }}
                    onCloseCallback={(open) => {
                        if (open) {
                            return;
                        }

                        dialogPromiseReject.current?.('no selection made');
                        // close modal
                        setVehiclesDialogOptions([]);
                    }}
                />
            )}
            <div className={styles.container}>
                <InputField
                    isError={isError}
                    clearSearchHistory={resetHistory}
                    selectedVehicle={vehicle}
                    removeSelectedVehicle={handleRemoveSelectedVehicle}
                    isFetchingProducts={!!inputValue && isLoadingProductsData}
                    isFetchingVehicle={!!inputValue && isLoadingVehicleData && !isVehicleDataIdle}
                    parentValue={inputValue}
                    items={quickSearchItemsList}
                    setParentInputValue={setInputValue}
                    onSelectItem={handleSelectItem}
                    onSubmit={handleSubmit}
                    onSearchButtonClick={handleSearchButtonClick}
                />
            </div>
        </div>
    );
};

function isCodeMatchType(hit: ProductTypes.IDirectHitQuickSearchResult) {
    return (
        hit?.matchType === ProductTypes.DirectHitType.FtzProductCode ||
        hit?.matchType === ProductTypes.DirectHitType.FtzCode ||
        hit?.matchType === ProductTypes.DirectHitType.Barcode
    );
}

export default SearchRoot;
