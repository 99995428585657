import { useMemo } from 'react';
import { MediaQuery, useMediaQueryDetect } from '~/shared/media-query-detect';
import styles from './delivery-count-down.module.scss';
import NavCounter from '~/shell/navigation/delivery-count-down/nav-counter/nav-counter.component';
import Text from '~/shared/text/text.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { useNextDeliveryForBasket } from '~/services/basket/use-next-delivery-for-basket';

const DeliveryCountDown = () => {
    const translate = useTranslations();
    const isPortable = useMediaQueryDetect(MediaQuery.MD);
    const { isLoading, data } = useNextDeliveryForBasket();

    const to = useMemo(() => {
        if (data?.nextDeliveryLastCallTime) {
            return new Date(data?.nextDeliveryLastCallTime);
        }
    }, [data]);

    if (isPortable || isLoading) {
        return null;
    }

    // The "to" can be null if there's no default address defined for the user.
    // If that's the case we hide the component.
    if (!to) {
        return null;
    }

    return (
        <div className={styles.root}>
            <Text textStyle="bodySmall" className={styles.label}>
                {translate('common.deliveryTimer', 'Næste leveringstur om:')}
            </Text>
            <NavCounter to={to} />
        </div>
    );
};
export default DeliveryCountDown;
