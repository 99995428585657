import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import cx from 'clsx';
import useMegaMenu from '~/services/content/use-mega-menu';
import { getOverlayVisibility, useNavStore } from '../../../shared/store';
import { MainNavLatestCarsParentLink, MainNavParentLink } from '../main-nav-parent-link/main-nav-parent-link';
import { MemoizedMainNavSkeleton } from '../main-nav-skeleton/main-nav-skeleton';
import { useScrollLock } from '~/features/navigation/shared/hooks';
import { NavScrollArea } from '~/features/navigation/shared/components';

import styles from './main-nav.module.scss';
import DepartmentChange from '~/shell/navigation/department-change/department-change.component';
import useUser from '~/libs/use-user';

/**
 * Used for rendering mega menu sidebar
 */
export function MainNav() {
    const router = useRouter();

    const { isInternalUser } = useUser();

    const {
        data: { megaMenu },
        isLoading: isLoadingMegaMenu,
    } = useMegaMenu();

    const { isOpen, isOverlayVisible, close, reset } = useNavStore((state) => ({
        ...getOverlayVisibility(state),
        isOpen: state.isOpen,
        reset: state.reset,
        close: state.close,
    }));

    useScrollLock({
        locked: isOpen || isOverlayVisible,
    });

    useEffect(() => {
        const _close = () => {
            close();
            reset();
        };

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key !== 'Escape') {
                return;
            }

            e.preventDefault();

            _close();
        };

        window.addEventListener('keydown', handleKeyDown);
        router.events.on('routeChangeComplete', _close);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            router.events.off('routeChangeComplete', _close);
        };
    }, []);

    const list = useMemo(() => {
        return megaMenu?.links?.map((link) => {
            if (link.documentType === 'recentCars') {
                return <MainNavLatestCarsParentLink key={link.id} link={link} />;
            }

            return <MainNavParentLink key={link.id} link={link} />;
        });
    }, [megaMenu]);

    return (
        <nav
            className={cx(styles.mainNav, {
                [styles.visible]: isOpen,
            })}
        >
            <NavScrollArea
                className={cx(styles.mainNavScrollArea, {
                    [styles.internal]: isInternalUser,
                })}
            >
                {isLoadingMegaMenu ? (
                    <ul className={styles.mainNavList}>
                        <MemoizedMainNavSkeleton />
                    </ul>
                ) : (
                    <ul className={styles.mainNavList}>{list}</ul>
                )}
                {isInternalUser ? <DepartmentChange /> : null}
            </NavScrollArea>
        </nav>
    );
}
