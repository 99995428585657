import Link from 'next/link';
import React, { useState } from 'react';
import { Vehicles as VehicleTypes } from '~/models/vehicles';
import Accordion from '~/shared/accordion/accordion.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import styles from './styled.module.scss';

interface IProps {
    carInfoData: VehicleTypes.IVehicleDetails;
}

export default function Content({ carInfoData }: IProps) {
    enum sections {
        none,
        vehData,
        breaksData,
        wheelsData,
        leasingData,
        inspectionsData,
    }

    const [activeSection, setActiveSection] = useState<sections>(sections.vehData);
    const translate = useTranslations();

    return (
        <div>
            <Accordion
                header={
                    <Text textStyle="body" color="primaryColor">
                        {translate('carInfoBanner.elaboratedCarInfo.vehicleData', 'Bildata')}
                    </Text>
                }
                isOpen={activeSection === sections.vehData}
                onClick={() => setActiveSection(activeSection === sections.vehData ? sections.none : sections.vehData)}
            >
                <table className={styles.contentTable}>
                    <tbody>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.number', 'Registreringsnummer')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.licensePlate?.number}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.vin', 'Stelnummer')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.vin}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.manufacturerName', 'Fabrikant')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.manufacturerName}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.modelName', 'Model')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.modelName}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.chassis', 'Stelnummer')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.chassis}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.modelTypeName', 'Model type navn')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.modelTypeName}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.modelTypeCode', 'Model typekode')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.modelTypeCode}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.modelTypeId', 'K typenr.')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.modelTypeId}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.firstRegistrationsDate', 'Første indregistreringsdato')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.firstRegistration}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.registrationDate', 'Registreringsdato')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.registrationDate}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.modelYear', 'Model år')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.modelYear}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.euroEnvironmentClass', 'Euronorm')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.euroEnvironmentClass}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.engineFuel', 'Drivmiddel ')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.engineFuel}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.engineCode', 'Motorkode')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.engineCode}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.engineKw', 'Effekt kw')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.engineKw}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.enginePs', 'HK')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.enginePs}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.engineCcm', 'Slagvolumen')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.engineCcm}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.engineCyl', 'Antal cylindre')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.engineCyl}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.drivingAxle', 'Drivaksel')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.drivingAxle}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.particulateFilter', 'Partikelfilter')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.particulateFilter}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.totalWeight', 'Totalvægt')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.totalWeight}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.serviceWeight', 'Tjenestevægt')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.serviceWeight}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.unbrakedTowingCapacity', 'Trækvægt UDEN bremser')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.unbrakedTowingCapacity}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.brakedTowingCapacity', 'Trækvægt MED bremser')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.brakedTowingCapacity}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.totalWeight', 'Totalvægt')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.totalWeight}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.serviceWeight', 'Tjenestevægt')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.serviceWeight}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.unbrakedTowingCapacity', 'Trækvægt UDEN bremser')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.unbrakedTowingCapacity}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.brakedTowingCapacity', 'Trækvægt MED bremser')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.brakedTowingCapacity}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.country', 'Land')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.licensePlate?.country}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.maxAxleWeight', 'Maksimalt akseltryk')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.maxAxleWeight}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.topSpeed', 'Maks hastighed')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.topSpeed}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.typePlateLocation', 'Typeplade placering')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.typePlateLocation}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.typeApproval', 'Typegodkendelsesnr')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.typeApproval}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.typeYearFrom', 'Type år fra')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.typeYearFrom}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.typeYearTo', 'Type år til')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.typeYearTo}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.manufacturerId', 'Fabrikant ID')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.manufacturerId}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.modelId', 'Model ID')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.modelId}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.fuelCode', 'Drivmiddelkode')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.fuelCode}
                                </Text>
                            </td>
                        </tr>

                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.engineType', 'Motortype')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.engineType}
                                </Text>
                            </td>
                        </tr>

                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.transmissionType', 'Gearkasse')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.transmissionType}
                                </Text>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Accordion>

            {(carInfoData.wheelsData?.length as number) > 0 ? (
                <Accordion
                    header={
                        <Text textStyle="body" color="primaryColor">
                            {translate('carInfoBanner.elaboratedCarInfo.wheelsData', 'Hjuldata')}
                        </Text>
                    }
                    isOpen={activeSection === sections.wheelsData}
                    onClick={() => setActiveSection(activeSection === sections.wheelsData ? sections.none : sections.wheelsData)}
                >
                    <table className={styles.contentTable}>
                        {carInfoData.wheelsData?.map((axel, i) => (
                            <tbody key={`${axel?.axleNumber}-${i}`}>
                                <tr>
                                    <td colSpan={2}>
                                        <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="center">
                                            {translate('carInfoBanner.elaboratedCarInfo.axleNumber', 'Aksel')} {axel.axleNumber}
                                        </Text>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{translate('carInfoBanner.elaboratedCarInfo.tireWidth', 'Dæk bredde (mm)')}</td>
                                    <td>
                                        <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                            {axel.tireWidth}
                                        </Text>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{translate('carInfoBanner.elaboratedCarInfo.tireProfile', 'Dæk profil')}</td>
                                    <td>
                                        <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                            {axel.tireProfile}
                                        </Text>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{translate('carInfoBanner.elaboratedCarInfo.rimDiameter', 'Fælg diameter')}</td>
                                    <td>
                                        <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                            {axel.rimDiameter}
                                        </Text>
                                    </td>
                                </tr>

                                <tr>
                                    <td>{translate('carInfoBanner.elaboratedCarInfo.loadIndex', 'Loadindex')}</td>
                                    <td>
                                        <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                            {axel.loadIndex}
                                        </Text>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{translate('carInfoBanner.elaboratedCarInfo.speedCode', 'Hastighedskode')}</td>
                                    <td>
                                        <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                            {axel.speedCode}
                                        </Text>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{translate('carInfoBanner.elaboratedCarInfo.maxAxleSpeed', 'Maksimal hastighed km/t')}</td>
                                    <td>
                                        <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                            {axel.maxAxleSpeed}
                                        </Text>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{translate('carInfoBanner.elaboratedCarInfo.maxAxleWeight', 'Maksimalt akseltryk')}</td>
                                    <td>
                                        <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                            {axel.maxAxleWeight}
                                        </Text>
                                    </td>
                                </tr>

                                <tr>
                                    <td>{translate('carInfoBanner.elaboratedCarInfo.rimDepth', 'Fælg indpresningsdybde (ET)')}</td>
                                    <td>
                                        <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                            {axel.rimDepth}
                                        </Text>
                                    </td>
                                </tr>

                                <tr>
                                    <td>{translate('carInfoBanner.elaboratedCarInfo.rimSize', 'Fælg bredde')}</td>
                                    <td>
                                        <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                            {axel.rimSize}
                                        </Text>
                                    </td>
                                </tr>

                                <Link href={carInfoData?.links?.tirePageLinks?.[axel.axleNumber] as string}>
                                    {translate('common.showAll', 'Vis alle')}
                                </Link>
                            </tbody>
                        ))}
                    </table>
                </Accordion>
            ) : null}

            <Accordion
                header={
                    <Text textStyle="body" color="primaryColor">
                        {translate('carInfoBanner.elaboratedCarInfo.leasingData', 'LeasingData')}
                    </Text>
                }
                isOpen={activeSection === sections.leasingData}
                onClick={() => setActiveSection(activeSection === sections.leasingData ? sections.none : sections.leasingData)}
            >
                <table className={styles.contentTable}>
                    <tbody>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.leasingStatus', 'Leasing')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.leasingStatus}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.leasingValidFrom', 'Leasing fra')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.leasingValidFrom}
                                </Text>
                            </td>
                        </tr>

                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.leasingValidUntil', 'Leasing til')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.leasingValidUntil}
                                </Text>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Accordion>

            <Accordion
                header={
                    <Text textStyle="body" color="primaryColor">
                        {translate('carInfoBanner.elaboratedCarInfo.inspectionsData', 'SynsData')}
                    </Text>
                }
                isOpen={activeSection === sections.inspectionsData}
                onClick={() => setActiveSection(activeSection === sections.inspectionsData ? sections.none : sections.inspectionsData)}
            >
                <table className={styles.contentTable}>
                    <tbody>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.lastInspectionDate', 'Sidste synsdato')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.lastInspection}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.nextApprovalDate', 'Dato for næste syn')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.nextApprovalDate}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.approvalText', 'Synsbemærkning')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.approvalText}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.unRegisterDate', 'Afmeldingsdato')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.unRegisterDate}
                                </Text>
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.nonApprovedInterconnection', 'synsfri sammenkobling')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.nonApprovedInterconnection}
                                </Text>
                            </td>
                        </tr>

                        <tr>
                            <td>{translate('carInfoBanner.elaboratedCarInfo.optionalApprovalDate', 'Valgfri synsdato')}</td>
                            <td>
                                <Text textStyle="monoMedium" fontWeight="semiBold" textAlign="right">
                                    {carInfoData?.optionalApprovalDate}
                                </Text>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Accordion>
        </div>
    );
}
