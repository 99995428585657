import React, { FC, useMemo } from 'react';
import { useOperationalNotificationActions, useOperationalNotifications } from '~/libs/queries/bff/hooks/use-operational-notifications';
import { Bff } from '~/models/bff.d';
import Fader from '~/shared/fader/fader.component';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import styles from './operational-notifications.module.scss';
import { NotificationBanner, NotificationBannerCloseButton, NotificationBannerColor } from '~/shared/notification-banner';

const OperationalNotifications: FC = () => {
    const { data } = useOperationalNotifications();
    const { isActive, setActive } = useOperationalNotificationActions(data);

    const iconName = useMemo(() => {
        switch (data?.status) {
            case Bff.NotificationBannerStatus.Resolved:
                return 'check';
            case Bff.NotificationBannerStatus.Warning:
                return 'warning';
        }
    }, [data?.status]);

    const color = useMemo<NotificationBannerColor>(() => {
        switch (data?.status) {
            case Bff.NotificationBannerStatus.Resolved:
                return 'dark-green';
            case Bff.NotificationBannerStatus.Warning:
            default:
                return 'blue';
        }
    }, [data?.status]);

    const handleClick = () => setActive(false);

    if (!isActive) return null;

    return (
        <Fader motionKey="operationalNotifications">
            <NotificationBanner color={color}>
                <Text textAlign="center" textStyle="body" color="lightColor">
                    {iconName ? <Svg name={iconName} className={styles.icon} /> : null}
                    {data?.message}
                </Text>
                <NotificationBannerCloseButton onClick={handleClick} />
            </NotificationBanner>
        </Fader>
    );
};

export default OperationalNotifications;
