import { useEffect, useState } from 'react';
import { useLayoutState } from '~/context/layout.context';
import { GA4AddListToCart, GA4AddPreviousOrderToCart } from '~/libs/ga4';
import { ConverterItemWithOpts } from '~/libs/ga4/converters/convert-generic/convert-generic';
import { useProductFits } from '~/libs/queries/products';
import { useOEMatchesVariants } from '~/libs/queries/products/hooks/use-oe-matches-variants';
import { useStockStatus } from '~/libs/queries/products/hooks/use-stock-status';
import { IBreadcrumb, getListName, useBreadcrumb } from '~/libs/queries/routing/hooks/use-breadcrumb';
import { Orders as OrdersTypes } from '~/models/orders.d';
import { Products as ProductTypes } from '~/models/products.d';
import { Vehicles as VehicleTypes } from '~/models/vehicles.d';
import Button from '~/shared/buttons/button/button.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Text from '~/shared/text/text.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';
import { RequiredProperty } from '~/types/generics';
import InvoiceItem from '../invoice-item/invoice-item.component';
import styles from './styled.module.scss';
import { useBasketBatchUpdate, useBasketBatchUpdateHandlers, useCurrentBasket } from '~/libs/queries/basket';

interface IInvoiceDetailsProps {
    invoice: OrdersTypes.IVehicleInvoiceDetails;
    licensePlate?: VehicleTypes.ILicensePlate;
    cmsCarId?: string;
    vin?: string;
    closeModal?: () => void;
}

/**
 * Create an GA4 add_to_cart event with all items in the invoice
 * @param items
 * @param basketId optional basket id
 * @param oeMatches
 * @param breadcrumb
 *
 * TODO: Check price
 */
const trackInvoiceAddToCart = (
    items: OrdersTypes.IVehicleInvoiceDetailItem[],
    basketId: string | undefined,
    oeMatches: Record<string, string> | undefined,
    breadcrumb: IBreadcrumb,
) => {
    if (!items) {
        return;
    }

    const trackingProducts: ConverterItemWithOpts[] = items.map((item, index) => {
        const {
            quantity,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            price: { currency, netPrice: price },
            itemId,
        } = item;
        return {
            opts: {
                index,
                currency,
                price,
                quantity,
                oeMatch: oeMatches?.[itemId as string] === 'yes',
            },
            ...item,
        };
    });

    // sum of all items using net price (aka customer price)
    const value = items.reduce((total, item) => total + (item.price?.netPrice || 0), 0);
    const currency = items[0].price?.currency;

    const listName = getListName(breadcrumb);
    GA4AddListToCart(trackingProducts, {
        basketId: basketId as string,
        currency: currency as string,
        value,
        listName,
    });
};

function selectInvoicesWithProductId(
    item: OrdersTypes.IVehicleInvoiceDetailItem,
): item is RequiredProperty<OrdersTypes.IVehicleInvoiceDetailItem, 'productId'> {
    return item.productId !== null && typeof item.productId !== 'undefined';
}

export default function InvoiceDetails({ invoice, licensePlate, cmsCarId, closeModal, vin }: IInvoiceDetailsProps) {
    const translate = useTranslations();

    const { data: currentBasket } = useCurrentBasket();

    const { handleSuccess: handleBatchUpdateBasketSuccess } = useBasketBatchUpdateHandlers();
    const { mutate: batchUpdateBasket } = useBasketBatchUpdate();

    const { items = [] } = invoice;
    const { data: oeMatches } = useOEMatchesVariants({
        variantIds: items?.filter(selectInvoicesWithProductId).map((v) => v.productId),
    });

    const { expertMode, selectedDepartment } = useLayoutState();
    const { data: breadcrumb } = useBreadcrumb();

    const [checkedOrder, setCheckedOrder] = useState<OrdersTypes.IVehicleInvoiceDetailItem[]>([]);
    const itemIds = items.map((order) => order?.itemId);
    const { data: stock } = useStockStatus(itemIds as string[]);

    const itemFitsRequest = {
        ftzCodes: itemIds,
        carModelTypeId: cmsCarId,
        includeExpertProducts: expertMode,
    };

    const { data: vehicleItemsFit, isLoading: isLoadingVehicleItemsFit } = useProductFits(itemFitsRequest as ProductTypes.IProductsFitsRequest);

    const changeHandler = (item: OrdersTypes.IVehicleInvoiceDetailItem) => {
        if (checkedOrder?.some((it) => it?.itemId === item?.itemId)) {
            setCheckedOrder((pev) => pev?.filter((it) => it?.itemId !== item?.itemId));
        } else {
            setCheckedOrder([...checkedOrder, item]);
        }
    };

    useEffect(() => {
        if (stock && vehicleItemsFit) {
            const checked = items?.filter((item) => {
                if (typeof item.itemId === 'string' && stock?.[item.itemId]?.statusCode === ProductTypes.StockStatusCode.NotInStock) {
                    return false;
                }
                return vehicleItemsFit[item.itemId as string];
            });
            setCheckedOrder(checked as OrdersTypes.IVehicleInvoiceDetailItem[]);
        }
    }, [items, stock, vehicleItemsFit]);

    const updateBasket = async () => {
        if (!currentBasket) {
            console.error('Could not batch update basket. Basket was undefined.');
            return;
        }

        batchUpdateBasket(
            {
                data: {
                    basketId: currentBasket.id,
                    items: checkedOrder?.map((order) => ({
                        carId: cmsCarId,
                        licensePlate,
                        itemId: order?.itemId,
                        count: order?.quantity,
                        vin,
                    })),
                },
                departmentId: selectedDepartment?.id,
                skipValidation: true,
            },
            {
                onSuccess: async (data, ...args) => {
                    // Handle default behaviour
                    handleBatchUpdateBasketSuccess(data, ...args);

                    GA4AddPreviousOrderToCart();
                    trackInvoiceAddToCart(items, data.id, oeMatches, breadcrumb as IBreadcrumb);

                    closeModal?.();
                },
            },
        );
    };

    return (
        <div className={styles.invoice}>
            <div className={styles.header}>
                <ValueWithCaption className={styles.headerItem} caption={translate('overview.invoiceNumber', 'Faktura nr.')}>
                    <Text fontWeight="semiBold" textStyle="bodyLarge" color="primaryColor">
                        {invoice.invoiceId}
                    </Text>
                </ValueWithCaption>
                <ValueWithCaption className={styles.headerItem} caption={translate('overview.caseNo', 'Sags nr.')}>
                    <Text textStyle="monoSmall">{invoice.caseNo}</Text>
                </ValueWithCaption>
                <ValueWithCaption className={styles.headerItem} caption={translate('overview.invoiceDate', 'Faktura dato')}>
                    <Text textStyle="monoSmall">{invoice.invoiceDateFormatted}</Text>
                </ValueWithCaption>
            </div>
            {invoice.items?.map((item) => (
                <InvoiceItem
                    isLoadingItemsFit={isLoadingVehicleItemsFit}
                    isSelected={checkedOrder?.some((order) => order?.itemId === item?.itemId)}
                    key={item.itemId}
                    item={item}
                    changeHandler={changeHandler}
                    itemFit={vehicleItemsFit && vehicleItemsFit[item?.itemId as string] !== false}
                    licensePlate={licensePlate}
                    isInStock={stock?.[item?.itemId as string]?.statusCode !== ProductTypes.StockStatusCode.NotInStock}
                />
            ))}
            <div className={styles.footer}>
                <Button buttonStyle="secondaryLight" onClick={updateBasket} disabled={checkedOrder.length === 0}>
                    {translate('overview.addOrderCopyBasket', 'Tilføj kopi af ordre til kurv')}
                </Button>
                <Button href={invoice.invoiceDetailsUrl} buttonStyle="secondary">
                    {translate('overview.goToFullOrder', 'Gå til fulde ordre')}
                </Button>
            </div>
        </div>
    );
}
