import { useRouter } from 'next/router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { addErrorToast, addSuccessToast, useLayoutDispatch } from '~/context/layout.context';
import useUser from '~/libs/use-user';
import { Vehicles as VehicleTypes } from '~/models/vehicles';
import { CarWindShieldEngineRequest } from '~/services/product/car-windshieldChange-request.service';
import Button from '~/shared/buttons/button/button.component';
import Checkbox from '~/shared/form-elements/checkbox/checkbox.component';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import PanelCard from '~/shared/panel-card/panel-card.component';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import styles from './styled.module.scss';

interface IWindshieldProps {
    license?: VehicleTypes.ILicensePlate | null;
    setModal?: (modal: null) => void;
}
export interface IPostData {
    wishesCalibration: boolean;
    license?: VehicleTypes.ILicensePlate;
}

export default function WindshieldExchange({ license, setModal }: IWindshieldProps) {
    const translate = useTranslations();
    const { locale } = useRouter();
    const [fetching, setFetching] = useState(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { country: licensePlateCountry, number: licensePlate } = license || {};
    const dispatch = useLayoutDispatch();
    const { register, handleSubmit } = useForm();
    const { user } = useUser();

    const onSubmit = async (postData: IPostData) => {
        const data = { ...postData, licensePlateCountry, licensePlate };

        try {
            const response = await CarWindShieldEngineRequest(data, locale as string, user);
            if (!response.errorMessage) {
                dispatch(addSuccessToast(translate('common.successfullyCreated', 'Oprettet med succes')));
                setFetching(false);

                window.setTimeout(() => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    setModal(null);
                }, 2000);
            } else {
                dispatch(addErrorToast(response.errorMessage));
            }
        } catch (error) {
            dispatch(addErrorToast(translate('common.somethingWentWrong', 'Der gik noget galt.')));
        } finally {
            setFetching(false);
        }
    };
    return (
        <div>
            <Svg name="ftz-shop-logo-multi-line-neg" className={styles.icon} />
            <Heading tagName="h3">{translate('internalDashboard.orderWindshield', 'Bestil rudeudskiftning')}</Heading>
            <PanelCard className={styles.modalContent}>
                <Text textStyle="body" tagName="p">
                    {translate(
                        'internalDashboard.windshieldModalText',
                        '“Når du klikker på knappen “send sms”, vil der blive sendt en SMS til nærmesterudeudskifter som vil kontakte dig”.',
                    )}
                </Text>
            </PanelCard>

            <div>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.formContentWrapper}>
                        <Checkbox {...register('wishesCalibration')}>
                            {translate('internalDashboard.includeAdjustment', 'Ruden skal kaliberes')}
                        </Checkbox>
                        <Button buttonStyle="primary" type="submit" fetching={fetching}>
                            {translate('internalDashboard.sendSms', 'Send SMS')}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
