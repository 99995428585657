import React from 'react';
import { Bff } from '~/models/bff';
import { StandardNavSub } from '~/features/navigation/standard-nav/components/standard-nav-sub/standard-nav-sub';
import { useNavStore } from '~/features/navigation/shared/store';
import { NavLink, NavLinkAnchor } from '~/features/navigation/shared/components';
import styles from './standard-nav-link.module.scss';

export type StandardNavLinkProps = {
    link: Bff.ILink;
};

export function StandardNavLink({ link }: StandardNavLinkProps) {
    const { add, close, reset } = useNavStore((state) => ({
        add: state.add,
        reset: state.reset,
        close: state.close,
    }));

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (link.subLinks && link.subLinks?.length > 0) {
            // Link has sub links, so instead of doing default routing,
            // We open the new links in a pane.
            e.preventDefault();

            add(link.id);
        }

        reset();
        close();
    };

    return (
        <NavLink>
            <NavLinkAnchor size="large" href={link.url} target={link.openInNewWindow ? '_blank' : undefined} onClick={handleClick}>
                {link?.icon?.url ? (
                    // eslint-disable-next-line @next/next/no-img-element
                    <img className={styles.icon} src={link?.icon?.url} alt={link?.icon?.altText} loading="lazy" style={{ width: link.icon.size }} />
                ) : null}

                {link?.title}

                {link?.subLinks?.length ? (
                    <svg className={styles.arrow}>
                        <use href="#sprite-chevron" />
                    </svg>
                ) : null}
            </NavLinkAnchor>

            {link?.subLinks?.length ? <StandardNavSub parent={link} /> : null}
        </NavLink>
    );
}
