import React from 'react';
import { useLayoutState } from '~/context/layout.context';

import { Portal } from '~/shared/portal';

import Notification from './notification/notification.component';
import styles from './notifications.module.scss';
import { INotification } from './notifications.d';

const Notifications = () => {
    const { notifications } = useLayoutState();

    if (!notifications?.length) return null;

    return (
        <Portal className={styles.container}>
            {notifications?.map((n: INotification) => (
                <Notification key={n.timeStamp} notification={n} />
            ))}
        </Portal>
    );
};

export default Notifications;
