import { LayoutActionTypes, useLayoutDispatch } from '~/context/layout.context';
import { CmsData } from '~/models/cms-data';

export function useLayoutVehicle() {
    const dispatch = useLayoutDispatch();

    const setVehicle = (vehicle: CmsData.IBaseVehicleInfo | null) => {
        dispatch({
            type: LayoutActionTypes.SetVehicle,
            payload: vehicle,
        });
    };

    const setLicensePlateCountry = (code: string | null) => {
        dispatch({
            type: LayoutActionTypes.SetLicensePlateCountry,
            payload: code,
        });
    };

    return { setVehicle, setLicensePlateCountry };
}
