import clsx from 'clsx';
import { FC } from 'react';
import { IBack } from '../navigation';
import styles from './back.module.scss';
import ExpertToggle from '~/shared/expert-toggle/expert-toggle.component';
import useUser from '~/libs/use-user';

// Children should be a sprite <use href="#sprite-chevron" />
const Back: FC<IBack> = ({ title, onClick, children: icons, className, documentType }) => {
    const { isInternalUser } = useUser();

    return (
        <div className={clsx(styles.title, className)}>
            {title}
            {documentType === 'cars' && isInternalUser && <ExpertToggle className={styles.expertToggle} />}
            <button aria-label="Back" type="button" className={styles.backButton} onClick={onClick}>
                <span className={styles.backIcon}>
                    <svg className={styles.backArrow}>{icons}</svg>
                </span>
            </button>
        </div>
    );
};

export default Back;
