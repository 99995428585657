import React, { FC } from 'react';
import { IMenuGroup } from '../../navigation.d';
import { Link } from '~/shared/link';
import { Bff } from '~/models/bff';

import styles from './sorted.module.scss';
import useNavigationLayout from '~/context/use-navigation.context';

interface ILinkWithExpertMode extends Bff.ILink {
    expertModeEnabled: boolean;
}

const Sorted: FC<IMenuGroup> = ({ data }) => {
    const { setNavState } = useNavigationLayout();
    const { links } = data as Bff.IIMenu;

    const sortedData = links?.reduce((acc, currentItem) => {
        if (!currentItem?.title) {
            return acc;
        }

        const firstLetter = currentItem.title[0];

        if (!acc[firstLetter]) {
            acc[firstLetter] = {
                firstLetter,
                relatedCars: [currentItem],
            };
        } else {
            acc[firstLetter].relatedCars.push(currentItem);
        }

        return acc;
    }, {} as any);

    if (!sortedData) {
        return null;
    }

    const sortedMenu = Object.keys(sortedData)
        .map((key) => ({
            id: key,
            title: key,
        }))
        .map((group) => ({
            ...group,
            subLinks: sortedData[group.id].relatedCars,
        }))
        .sort((a, b) => {
            if (a.title < b.title) return -1;
            if (a.title > b.title) return 1;
            return 0;
        });

    return sortedMenu?.length > 0 ? (
        <ul className={styles.sorted}>
            {sortedMenu?.map((letter) => (
                <li key={letter.id}>
                    <p className={styles.letter}>{letter?.title}</p>

                    <ul className={styles.companys}>
                        {letter?.subLinks.map(
                            (group: ILinkWithExpertMode, index: number) =>
                                group?.url && (
                                    <li key={`${group.id}-${index}`}>
                                        <Link
                                            prefetch={false}
                                            href={group?.url}
                                            className={group.expertModeEnabled ? styles.expertLink : styles.link}
                                            onClick={() =>
                                                setNavState({
                                                    isOpen: false,
                                                })
                                            }
                                        >
                                            {group?.title}
                                        </Link>
                                    </li>
                                )
                        )}
                    </ul>
                </li>
            ))}
        </ul>
    ) : null;
};

export default Sorted;
