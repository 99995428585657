import React from 'react';
import CarInfoItem from './car-info-item/car-info-item.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Vehicles as VehicleTypes } from '~/models/vehicles';

interface ICarInfoBoxProps {
    isLoading: boolean;
    carInfoData?: VehicleTypes.IVehicleDetails;
}

export const CarInfoBox = ({ isLoading, carInfoData }: ICarInfoBoxProps) => {
    const translate = useTranslations();

    return (
        <>
            <CarInfoItem className="showXLUp" loading={isLoading} title={translate('carInfoBanner.engineCode', 'Motortype')}>
                {carInfoData?.engineType} / {carInfoData?.engineCode}
            </CarInfoItem>
            <CarInfoItem className="showXLUp" loading={isLoading} title={translate('carInfoBanner.firstRegistrationDate', '1. reg.')}>
                {carInfoData?.firstRegistration}
            </CarInfoItem>
            {carInfoData?.nextApprovalDate && (
                <CarInfoItem className="showXLUp" loading={isLoading} title={translate('carInfoBanner.lastApprovalDate', 'sidste syn')}>
                    {carInfoData?.nextApprovalDate}
                </CarInfoItem>
            )}
            <CarInfoItem className="showXXLUp" loading={isLoading} title={translate('carInfoBanner.vin', 'Stelnr.')}>
                {carInfoData?.vin}
            </CarInfoItem>
        </>
    );
};
