import React, { useCallback, useState } from 'react';
import { GA4SeePreviousOrder } from '~/libs/ga4';
import { IWithClassName } from '~/models/dev';
import { Vehicles as VehicleTypes } from '~/models/vehicles.d';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import LicensePlate from '~/shared/license-plate/license-plate.component';
import PreviousOrders from './previous-orders/previous-orders.component';
import styles from './styled.module.scss';
import { Modal } from '~/features/primitives/modal';

interface IVehicleInvoicesProps extends IWithClassName {
    licensePlate?: VehicleTypes.ILicensePlate;
    vin: string;
    vehicleInvoicesCount: number;
    isLoading: boolean;
    licensePlateOrVin: string;
    cmsCarId: string;
}

export default function VehicleInvoices({
    className,
    licensePlate,
    vin,
    licensePlateOrVin,
    vehicleInvoicesCount,
    isLoading,
    cmsCarId,
}: IVehicleInvoicesProps) {
    const [isPrevOrdersOpen, setPrevOrders] = useState(false);
    const translate = useTranslations();

    const handleOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setPrevOrders(false);
    }, []);

    if (!licensePlateOrVin) return null;

    return (
        <div className={className}>
            <Modal
                position="right"
                open={isPrevOrdersOpen}
                onOpenChange={handleOpenChange}
                title={translate('overview.ordersHistory', 'Ordrehistorik')}
                meta={<LicensePlate className={styles.licensePLate} licensePlate={licensePlate} vin={vin} />}
            >
                <PreviousOrders
                    closeModal={() => setPrevOrders(false)}
                    licensePlate={licensePlate}
                    cmsCarId={cmsCarId}
                    vin={vin}
                    licensePlateOrVin={licensePlateOrVin}
                />
            </Modal>
            <ButtonCircle
                iconName="drawer"
                badge={vehicleInvoicesCount}
                onClick={() => {
                    GA4SeePreviousOrder();

                    setPrevOrders(true);
                }}
                fetching={isLoading}
                tooltipText={translate('carInfoBanner.seePreviousOrders', 'Se tidligere ordrer')}
            />
        </div>
    );
}
