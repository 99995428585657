import clsx from 'clsx';
import React, { FC, Ref, forwardRef, useCallback } from 'react';
import { useManualWebOrdersCount } from '~/libs/queries/users/hooks/use-manual-web-orders-count';
import { useEndSession } from '~/libs/use-end-session';
import { Bff } from '~/models/bff';
import { Umbraco } from '~/models/umbraco.d';
import { authRedirectEndpoints } from '~/services/auth-endpoints';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { useStaticContent } from '~/libs/queries/bff';
import IconBadge from '~/shared/icon-badge';
import { CMSLink, Link } from '~/shared/link';
import { IMenuDropdown } from '../navigation';
import Back from './back';
import styles from './service-dropdown.module.scss';

interface IServiceDropdown extends IMenuDropdown {
    addCreditLink?: boolean;
}

const ServiceDropdown: FC<IServiceDropdown> = forwardRef(
    ({ isActive, closeDropdownMenu, title, links, withLogout, addCreditLink = false }, ref?: Ref<HTMLDivElement>) => {
        const translate = useTranslations();
        const { data: staticContent } = useStaticContent();
        const { data } = useManualWebOrdersCount();
        const { endSession } = useEndSession();
        const creditUrl = staticContent?.myFtz?.filter((mt: Bff.ILink) => mt?.title === 'Fakturaoversigt')[0];

        const handleLogOut = useCallback<React.MouseEventHandler<HTMLAnchorElement>>(async (e) => {
            e.preventDefault();

            await endSession();
        }, []);

        return (
            <div
                ref={ref}
                className={clsx(styles.serviceDropdown, {
                    [styles.open]: isActive,
                })}
            >
                <div className={styles.scroller}>
                    <Back title={title} onClick={closeDropdownMenu} className={styles.back}>
                        <use href="#sprite-close" />
                    </Back>

                    <ul className={styles.list}>
                        {links?.length &&
                            links?.map((link, linkIndex) => {
                                const isManualOrdersWidget = link?.widgetTypes?.includes(Umbraco.WidgetTypes.manualWebordersOverviewWidget);

                                return (
                                    <li key={`${link.id}-${linkIndex}`} className={styles.item}>
                                        <CMSLink
                                            className={styles.link}
                                            link={link}
                                            onClick={() => {
                                                closeDropdownMenu();
                                            }}
                                        >
                                            {link.title}

                                            {isManualOrdersWidget && Boolean(data?.count) && (
                                                <IconBadge className={styles.badge} value={data?.count as number} />
                                            )}
                                        </CMSLink>
                                    </li>
                                );
                            })}

                        {withLogout && (
                            <li key="logout" className={styles.item}>
                                <Link className={styles.link} href={authRedirectEndpoints.logout} onClick={handleLogOut} shouldRenderStaticLink>
                                    {translate('header.logOut', 'Log ud')}
                                </Link>
                            </li>
                        )}
                        {addCreditLink ? (
                            <li key="addCredit" className={styles.item}>
                                <Link className={styles.link} href={creditUrl?.url} onClick={closeDropdownMenu}>
                                    {translate('overview.establishCredit', 'Opret Kreditering')}
                                </Link>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </div>
        );
    },
);

export default ServiceDropdown;
