import { IQuickSearchItem, IVehicleSearchItem } from './search-root.models';
import { Products as ProductTypes } from '~/models/products';
import { Vehicles as VehicleTypes } from '~/models/vehicles';
import { getVehicleText } from '~/helpers/latest-vehicles.helper';

export const formatVehicleData = (vehicle: VehicleTypes.ICommonVehicleDetails): IVehicleSearchItem => {
    return {
        ...vehicle,
        type: 'Vehicle',
        text: getVehicleText(vehicle),
        secondaryText: `${vehicle.manufacturerName} ${vehicle.modelName} ${vehicle.modelTypeName}`,
        url: vehicle.sparePartsUrl,
        isDirectMatch: true,
    };
};

const formatVehicleSuggestions = (vehicles: VehicleTypes.ICommonVehicleDetails[] = []): IVehicleSearchItem[] => {
    return vehicles.map((vehicle) => formatVehicleData(vehicle)).filter(Boolean);
};

interface IFormatQuickSearchDataProps {
    products: ProductTypes.IQuickSearchResultList;
    vehicles: VehicleTypes.ICommonVehicleDetails[];
    isMobile: boolean;
}

export const formatQuickSearchData = ({ products, vehicles, isMobile }: IFormatQuickSearchDataProps): IQuickSearchItem[] => {
    const maxItemsPrSection = isMobile ? 2 : 3;

    const directHits = products?.directHits?.slice(0, maxItemsPrSection) || [];

    const crossNumbers = products?.crossNumbers?.slice(0, maxItemsPrSection) || [];

    const sparePartCategories = products?.sparePartCategories?.slice(0, maxItemsPrSection) || [];

    const universalCategories = products?.universalCategories?.slice(0, maxItemsPrSection) || [];

    const suggestions = products?.suggestions?.slice(0, maxItemsPrSection) || [];

    const vehicleSuggestions = formatVehicleSuggestions(vehicles);

    const suggestionsList = [...directHits, ...crossNumbers, ...sparePartCategories, ...universalCategories, ...suggestions];

    if (products?.searchResultPage) {
        suggestionsList.push(products.searchResultPage);
    }

    if (vehicleSuggestions) {
        return [...vehicleSuggestions, ...suggestionsList];
    }
    return suggestionsList;
};

export function isVehicleSearchItem(item: any): item is IVehicleSearchItem {
    return typeof item.vehicleClass !== 'undefined';
}
