import { FC, useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { LayoutActionTypes, useLayoutDispatch, useLayoutState } from '~/context/layout.context';
import { useStaticContent } from '~/libs/queries/bff';
import { IWithClassName } from '~/models/dev';
import Button from '~/shared/buttons/button/button.component';
import LicensePlate from '~/shared/license-plate/license-plate.component';
import styles from './countries.module.scss';

interface IProps extends IWithClassName {
    isOpen: boolean;
    close: () => void;
}

const Countries: FC<IProps> = ({ className, isOpen, close }) => {
    const wrapperRef = useRef(null);
    const { licensePlateCountry } = useLayoutState();
    const dispatch = useLayoutDispatch();
    const { data: staticContent } = useStaticContent();

    useOnClickOutside(wrapperRef, () => {
        if (isOpen) {
            close();
        }
    });

    return (
        <div className={className} ref={wrapperRef}>
            <div className={styles.countriesList}>
                {staticContent?.licensePlateLanguages?.map((c) => {
                    return (
                        <Button
                            key={c.code}
                            onClick={() => {
                                dispatch({
                                    type: LayoutActionTypes.SetLicensePlateCountry,
                                    payload: c.code ?? null,
                                });
                                close();
                            }}
                            className={c.code === licensePlateCountry ? styles.selected : styles.deselected}
                            buttonStyle="clean"
                            buttonSize="link"
                        >
                            <LicensePlate
                                isFlex
                                licensePlate={{
                                    country: c.code,
                                    number: c.name,
                                }}
                            />
                        </Button>
                    );
                })}
            </div>
        </div>
    );
};

export default Countries;
