import React, { FC, useMemo } from 'react';
import { CmsData } from '~/models/cms-data';
import styles from './search-tag.module.scss';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';

interface IVehicleTagProps {
    vehicle: CmsData.IBaseVehicleInfo;
}

export const VehicleTag: FC<IVehicleTagProps> = ({ vehicle }) => {
    const text = useMemo(() => {
        if (vehicle?.licensePlate) {
            return `${vehicle?.licensePlate?.country} ${vehicle?.licensePlate?.number}`;
        }

        if (vehicle?.vin) {
            return vehicle?.vin;
        }

        return vehicle?.carId ?? null;
    }, [vehicle?.carId, vehicle?.licensePlate, vehicle?.vin]);

    return (
        <div className={styles.tagContent}>
            <Svg
                name={
                    // TODO: Make Bff.VehicleClass to an enum of string instead of auto assigned integers
                    vehicle.vehicleClass !== 2 ? 'car' : 'truck'
                }
                className={styles.tagIcon}
            />
            <Text fontWeight="regular" textStyle="monoMedium" className={styles.tagText}>
                {text}
            </Text>
        </div>
    );
};

interface IProps {
    onClose: () => void;
    children: React.ReactNode;
}

const SearchTag: FC<IProps> = ({ children, onClose }) => (
    <div className={styles.wrapper}>
        <button aria-label="Search tag" className={styles.closeBtn} type="button" onClick={onClose}>
            <Svg name="close" className={styles.closeIcon} />
        </button>
        {children}
    </div>
);

export default SearchTag;
