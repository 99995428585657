import { IWithClassName } from '~/models/dev';
import Skeleton from '~/shared/skeleton';
import Text from '~/shared/text/text.component';
import ValueWithCaption from '~/shared/value-with-caption/value-with-caption.component';

interface ICarInfoBoxProps extends IWithClassName {
    title: string;
    loading?: boolean;
}

export default function CarInfoItem({ className, title, children, loading = false }: ICarInfoBoxProps) {
    return (
        <ValueWithCaption className={className} caption={title}>
            {loading ? (
                <Skeleton isDark style={{ height: '15px', width: '100%', marginTop: '5px' }} />
            ) : (
                <Text textStyle="monoSmall">{children || '-'}</Text>
            )}
        </ValueWithCaption>
    );
}
