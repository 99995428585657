import React, { useMemo } from 'react';
import cx from 'clsx';
import type { Bff } from '~/models/bff';
import Table from '~/shell/navigation/chunks/menu-groups/table';
import LatestVehicles from '~/shell/navigation/chunks/menu-groups/latest-vehicles';
import Featured from '~/shell/navigation/chunks/menu-groups/featured';
import Sorted from '~/shell/navigation/chunks/menu-groups/sorted';
import { MainNavBackButton } from '~/features/navigation/main-nav/components/main-nav-back-button/main-nav-back-button.component';
import { StandardNav } from '~/features/navigation/standard-nav';
import { useNavStore } from '~/features/navigation/shared/store';
import { CatalogNav } from '~/features/navigation/catalog-nav';
import { NavScrollArea } from '~/features/navigation/shared/components';
import styles from './main-nav-overlay.module.scss';

export type MainNavOverlayProps = {
    link: Bff.ITopLink;
};
export function MainNavOverlay({ link }: MainNavOverlayProps) {
    const { reset, isVisible } = useNavStore((state) => ({
        reset: state.reset,
        // TODO: Clarify if undefined is possible
        isVisible: link?.id && state.path.includes(link.id),
    }));

    // TODO: Clarify if displayMode can be undefined
    const displayMode = link?.displayMode ?? 'wide';

    const menuGroups = useMemo(() => {
        return link.menuGroups?.map((group) => {
            const commonProps = {
                key: group.title,
                displayMode,
                data: group.data,
                title: link.title ?? group.title,
                isUniversalMenu: link?.documentType === 'products',
            };

            switch (group.type) {
                case 'Table':
                    return <Table {...commonProps} />;
                case 'LatestVehicles':
                    return <LatestVehicles {...commonProps} />;
                case 'Featured':
                    return <Featured {...commonProps} />;
                case 'Sorted':
                    return <Sorted {...commonProps} />;
                default:
                    if (!group.data?.links) {
                        return null;
                    }

                    return <StandardNav links={group.data.links} mode={displayMode} />;
            }
        });
    }, [displayMode, link?.documentType, link.menuGroups, link.title]);

    if (!isVisible) {
        return null;
    }

    if (link.documentType === 'products' && link.menuGroups?.[0].data) {
        return (
            <div
                onClick={reset}
                className={cx(styles.mainNavOverlay, {
                    [styles.visible]: isVisible,
                })}
            >
                <div onClick={(e) => e.stopPropagation()}>
                    <CatalogNav parent={link} menu={link.menuGroups?.[0].data} path={[link.id]} />
                </div>
            </div>
        );
    }

    return (
        <div
            onClick={reset}
            className={cx(styles.mainNavOverlay, {
                [styles.visible]: isVisible,
            })}
        >
            <div
                className={cx(styles.mainNavOverlayContentArea, {
                    [styles.visible]: isVisible,
                    [styles.small]: displayMode === 'small',
                    [styles.medium]: displayMode === 'medium',
                    [styles.large]: displayMode === 'wide',
                })}
                onClick={(e) => e.stopPropagation()}
            >
                <NavScrollArea className={styles.mainNavOverLayScrollArea}>
                    <MainNavBackButton onClick={reset} type={link.documentType} title={link.title} />
                    {isVisible && menuGroups ? menuGroups : null}
                </NavScrollArea>
            </div>
        </div>
    );
}
