import { FC } from 'react';
import { motion } from 'framer-motion';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { ITogglerPath } from '../navigation.d';
import { duration } from '../navigation.settings';

import styles from './toggler.module.scss';
import { useNavStore } from '~/features/navigation/shared/store';

const Path: FC<ITogglerPath> = ({ transition, ...rest }) => (
    <motion.path
        vectorEffect="non-scaling-stroke"
        className={styles.path}
        transition={
            transition || {
                duration,
                times: [0, 0.5, 1],
            }
        }
        {...rest}
    />
);

const Toggler = () => {
    const translate = useTranslations();

    const { isOpen, toggle } = useNavStore((state) => ({
        isOpen: state.isOpen,
        toggle: state.toggle,
    }));

    return (
        <button type="button" className={styles.toggler} onClick={toggle}>
            <motion.svg width="26" height="26" viewBox="0 0 24 24" className={styles.icon} initial={false} animate={isOpen ? 'open' : 'closed'}>
                <Path
                    d="M2.5 6.5 H20.5"
                    variants={{
                        closed: {
                            y: [5, 5, 0],
                            rotate: [45, 0, 0],
                        },
                        open: {
                            y: [0, 5, 5],
                            rotate: [0, 0, 45],
                        },
                    }}
                />
                <Path
                    d="M2.5 11.5 H20.5"
                    transition={{
                        duration,
                        times: [0, 0.5, 0.51],
                    }}
                    variants={{
                        closed: {
                            opacity: [0, 0, 1],
                        },
                        open: {
                            opacity: [1, 1, 0],
                        },
                    }}
                />
                <Path
                    d="M2.5 16.5 H20.5"
                    variants={{
                        closed: {
                            y: [-5, -5, 0],
                            rotate: [-45, 0, 0],
                        },
                        open: {
                            y: [0, -5, -5],
                            rotate: [0, 0, -45],
                        },
                    }}
                />
            </motion.svg>

            {translate('header.mobileToggler', 'Menu')}
        </button>
    );
};

export default Toggler;
