import clsx from 'clsx';
import customProtocolCheck from 'custom-protocol-check';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { ROUTER_QUERY_FUNCTIONS } from '~/constants/query';
import { addErrorToast, addInfoToast, useLayoutDispatch } from '~/context/layout.context';
import { useStaticContent } from '~/libs/queries/bff';
import { isWindows } from '~/helpers';
import { GA4CarInfoAdditional, GA4CarInfoShowMore } from '~/libs/ga4';
import { useExternalLogin } from '~/libs/queries/users/hooks/use-external-login';
import { useVehicleDetails } from '~/libs/queries/vehicles/hooks/use-vehicle-details';
import { useVehicleInvoicesCount } from '~/libs/queries/vehicles/hooks/use-vehicle-invoices-count';
import useUser from '~/libs/use-user';
import { Vehicles as VehicleTypes } from '~/models/vehicles.d';
import useVantageDashboard from '~/services/content/use-vantage-dashboard';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import ExpertToggle from '~/shared/expert-toggle/expert-toggle.component';
import { usePendingInspectionNotification } from '~/shared/hooks/use-pending-inspection-notification';
import useTranslations from '~/shared/hooks/use-translations.hook';
import LicensePlate from '~/shared/license-plate/license-plate.component';
import { Link } from '~/shared/link';
import BaseCarInfo from './components/base-car-info/base-car-info.component';
import { CarInfoBox } from './components/car-info-box/car-info-box.component';
import { CollapsedInfoBanner } from './components/collapsed-info-banner/collapsed-info-banner.component';
import ExpandCollapseButton from './components/expand-collapse-button/expand-collapse-button.component';
import VehicleInvoices from './components/vehicle-invoices/vehicle-invoices.component';
import styles from './styled.module.scss';
import { ICarInfoBannerData } from './use-car-info-banner.hook';
import { useCurrentBasket } from '~/libs/queries/basket';

export default function CarInfoBanner({ carLink, arrowLink, vehicle }: ICarInfoBannerData) {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();
    const { data: basket } = useCurrentBasket();
    const { isInternalUser } = useUser();
    const { data: staticContent } = useStaticContent();
    const { openVantageDashboard } = useVantageDashboard();
    const { data: externalLoginsData } = useExternalLogin();
    const { carId: cmsCarId, licensePlate: cmsLicensePlate, vin: cmsVin } = vehicle || {};
    const { data: carInfoData, isLoading } = useVehicleDetails(cmsLicensePlate as VehicleTypes.ILicensePlate, cmsCarId as string, cmsVin as string);
    const { licensePlate, vin, modelTypeId } = carInfoData || {};
    const [isOpen, setIsOpen] = useState(false);
    const router = useRouter();
    const links = carInfoData?.links;

    useEffect(() => {
        const handleRouteChange = () => {
            setIsOpen(false);
        };

        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, []);

    useEffect(() => {
        document.body.classList[isOpen ? 'add' : 'remove'](styles.noScroll);

        return () => {
            if (isOpen) {
                document.body.classList.remove(styles.noScroll);
            }
        };
    }, [isOpen]);

    const handleClickVehCareOil = async () => {
        GA4CarInfoAdditional('Smøretabel');

        openVantageDashboard({
            basketId: basket?.id as string,
            carId: carInfoData?.modelTypeId,
            licensePlate: carInfoData?.licensePlate,
        });
    };

    const handleClickAutoFrontal = () => {
        GA4CarInfoAdditional('Autodata');

        const handleRejection = () => {
            dispatch(
                addErrorToast(translate('carInfoBanner.autoFrontalFails', 'Kunne ikke åbne AutoFrontal. Programmet er muligvis ikke installeret')),
            );
        };

        const handleFulfillment = () => {
            window.open(links?.autoFrontalLink, '_self');
        };

        dispatch(addInfoToast(translate('carInfoBanner.autoFrontalOpens', 'Åbner AutoFrontal')));

        customProtocolCheck(links?.autoFrontalLink as string, handleRejection, handleFulfillment);
    };

    const handleNFLinkClick = () => {
        GA4CarInfoAdditional('NF-data');

        window.open(links?.nfDataLink, '_blank');
    };

    const handleHaynesProClick = () => {
        if (!isInternalUser && !externalLoginsData?.haynes?.userName) {
            return router.push({
                pathname: staticContent?.urlMappings?.externalLogins,
                query: { fn: ROUTER_QUERY_FUNCTIONS.haynesProPrompt },
            });
        }
        GA4CarInfoAdditional('HaynesPro');

        window.open(links?.haynesLink, '_blank');
    };

    const licensePlateOrVin = licensePlate?.number || vin || null;
    const { data: vehicleInvoicesCount, isLoading: loading } = useVehicleInvoicesCount(licensePlateOrVin as string);

    usePendingInspectionNotification({
        nextApprovalDateTime: carInfoData?.nextApprovalDateTime ? String(carInfoData.nextApprovalDateTime) : undefined,
        licensePlate: carInfoData?.licensePlate,
    });

    return (
        <div className={styles.root}>
            {isInternalUser && <ExpertToggle className={styles.expertToggle} textClassName={styles.expertToggleText} />}

            <div className="container-fluid">
                <div
                    className={clsx(styles.topSection, {
                        [styles.topSectionExpertToggle]: isInternalUser,
                    })}
                >
                    <ButtonCircle
                        iconName="chevron-left"
                        onClick={() => {
                            arrowLink ? router.push(arrowLink) : router.back();
                        }}
                        fetching={isLoading}
                    />
                    <Link href={carLink} className={styles.bannerHeaderLink}>
                        <LicensePlate licensePlate={licensePlate} carId={modelTypeId} vin={vin} className={styles.bannerHeaderPlate} isTruncated />
                        <BaseCarInfo
                            className={styles.bannerHeaderInfo}
                            manufacturerName={carInfoData?.manufacturerName}
                            modelName={carInfoData?.modelName}
                            modelTypeName={carInfoData?.modelTypeName}
                            typeYearFrom={carInfoData?.typeYearFrom as string}
                            typeYearTo={carInfoData?.typeYearTo as string}
                            loading={isLoading}
                            vehicleTypeEngineCodes={carInfoData?.vehicleTypeEngineCodes as string[]}
                        />
                    </Link>
                    <div className={styles.bannerHeaderAttrList}>
                        {(vehicleInvoicesCount as number) > 0 && (
                            <VehicleInvoices
                                className="showMdUp"
                                licensePlate={licensePlate}
                                vin={vin as string}
                                vehicleInvoicesCount={vehicleInvoicesCount as number}
                                isLoading={loading}
                                licensePlateOrVin={licensePlateOrVin as string}
                                cmsCarId={cmsCarId as string}
                            />
                        )}
                        {isWindows && links?.autoFrontalLink && (
                            <ButtonCircle
                                tooltipClassName="showMdUp"
                                iconName="car"
                                tooltipText={translate('carInfoBanner.carData', 'Autodata')}
                                onClick={handleClickAutoFrontal}
                                fetching={isLoading}
                            />
                        )}
                        {links?.haynesLink && (
                            <ButtonCircle
                                tooltipClassName="showMdUp"
                                fetching={isLoading}
                                fillIcon
                                iconName="haynesPro"
                                onClick={handleHaynesProClick}
                                tooltipText={translate('carInfoBanner.haynesPro', 'HaynesPro')}
                            />
                        )}
                        {links?.nfDataLink && (
                            <ButtonCircle
                                tooltipClassName="showMdUp"
                                fetching={isLoading}
                                fillIcon
                                iconName="nf-data"
                                onClick={handleNFLinkClick}
                                tooltipText={translate('carInfoBanner.nFData', 'NF-Data')}
                            />
                        )}
                        {links?.vantageDashboard && (
                            <span>
                                <ButtonCircle
                                    tooltipClassName="showMdUp"
                                    tooltipText={translate('carInfoBanner.elf', 'Elf')}
                                    iconName="drop"
                                    onClick={handleClickVehCareOil}
                                    fetching={isLoading}
                                    target="_blank"
                                />
                            </span>
                        )}
                        <CarInfoBox isLoading={isLoading} carInfoData={carInfoData} />
                    </div>
                </div>
                <CollapsedInfoBanner
                    carInfoData={carInfoData as VehicleTypes.IVehicleDetails}
                    links={links}
                    isLoading={isLoading}
                    handleCLickVehCareOil={handleClickVehCareOil}
                    handleClickAutoFrontal={handleClickAutoFrontal}
                    handleHaynesProClick={handleHaynesProClick}
                    handleNFLinkClick={handleNFLinkClick}
                    licensePlate={licensePlate}
                    vin={vin}
                    modelTypeId={modelTypeId}
                    isOpen={isOpen}
                    vehicleInvoicesCount={vehicleInvoicesCount as number}
                    loading={loading}
                    licensePlateOrVin={licensePlateOrVin as string}
                />
            </div>

            <ExpandCollapseButton
                className={styles.expandCollapseButton}
                isOpen={isOpen}
                onClick={() => {
                    setIsOpen((prevIsOpen) => {
                        if (!prevIsOpen) {
                            GA4CarInfoShowMore();
                        }
                        return !prevIsOpen;
                    });
                }}
            />
        </div>
    );
}
