import { Vehicles as VehicleTypes } from '~/models/vehicles';

export const getVehicleText = (vehicle: VehicleTypes.ICommonVehicleDetails): string => {
    if (vehicle?.licensePlate) {
        return `${vehicle?.licensePlate?.country} ${vehicle?.licensePlate?.number}`;
    }

    if (vehicle?.vin) return vehicle?.vin?.toString();

    return vehicle?.modelTypeId?.toString() as string;
};
