import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import React, { useEffect, useMemo } from 'react';
import { addSuccessToast, useLayoutDispatch, useLayoutState } from '~/context/layout.context';
import { isBrowser } from '~/helpers';
import { Bff } from '~/models/bff';
import { ISlug } from '~/models/dev';
import useCarInfoBanner from '~/widgets/car-info-banner/use-car-info-banner.hook';
import ImpersonateBanner from '~/page-elements/impersonate/impersonate-banner.component';
import SwitchCustomerOrBasket from '~/page-elements/switch-customer-or-basket/switch-customer-or-basket.component';
import Breadcrumbs from '~/shared/breadcrumbs/breadcrumbs.component';
import FtzPlusIntegration from '~/shared/ftz-plus-integration/ftz-plus-integration.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import OperationalNotifications from '~/shared/operational-notifications/operational-notifications.component';
import { Portal } from '~/shared/portal';
import Footer from '~/shell/footer/footer.component';
import Logo from '~/shell/navigation/chunks/logo';
import Service from '~/shell/navigation/chunks/service';
import Toggler from '~/shell/navigation/chunks/toggler';
import Notifications from '~/shell/notifications/notifications.component';
import SearchRoot from '~/shell/search-root/search-root.component';
import CarInfoBanner from '~/widgets/car-info-banner/car-info-banner.component';
import Sprites from '../sprites/sprites.component';
import styles from './standard.module.scss';
import { useCMSPage } from '~/libs/queries/bff';
import { MainNav } from 'features/navigation/main-nav';
import useUser from '~/libs/use-user';
import DeliveryCountDown from '~/shell/navigation/delivery-count-down/delivery-count-down.component';
import { TruckMultiMatchBanner } from '~/shared/truck-multi-match-banner';
import { useCurrentBasket, useUpdateOrderDetails } from '~/libs/queries/basket';
import { MediaQuery, useMediaQueryDetect } from '~/shared/media-query-detect';
import DepartmentChange from '~/shell/navigation/department-change/department-change.component';

const ErrorReport = dynamic(() => import('~/page-elements/error-report/error-report.component'), { ssr: false });

const StandardLayout = ({ children }: ISlug) => {
    const { data: currentBasket } = useCurrentBasket();
    const { mutate: updateOrderDetails } = useUpdateOrderDetails();

    const translate = useTranslations();
    const dispatch = useLayoutDispatch();
    const router = useRouter();
    const { data: pageContent } = useCMSPage();
    const isPortable = useMediaQueryDetect(MediaQuery.MD);
    const { loading, isRedirecting } = useLayoutState();
    const { isInternalUser } = useUser();

    useEffect(() => {
        if (isBrowser && router?.query?.welcome) {
            dispatch(
                addSuccessToast(
                    translate('common.newCustomerWelcomeToast', 'Hej og velkommen - vi håber du kommer godt i gang med FTZs nye katalog: FTZshop'),
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router]);

    // Use car info banner component to control the vehicle on the page
    const { carInfoBannerData } = useCarInfoBanner(pageContent as Bff.IPageContentViewModel);

    useEffect(() => {
        if (isBrowser && currentBasket?.id && router?.query?.caseNo) {
            updateOrderDetails({
                basketId: currentBasket.id,
                details: {
                    caseNo: String(router.query.caseNo),
                },
            });
        }
    }, [currentBasket?.id, router?.query?.caseNo]);

    const truckBanner = useMemo(() => {
        if (pageContent?.type !== 'sparePartCategoryListPage') {
            return null;
        }

        return <TruckMultiMatchBanner />;
    }, [pageContent?.type]);

    return (
        <div>
            <div className={styles.page}>
                <header className={styles.header}>
                    <Toggler />
                    {isPortable ? (
                        <Portal>
                            <SearchRoot />
                        </Portal>
                    ) : (
                        <SearchRoot />
                    )}

                    <Service isPortable={isPortable} />
                </header>
                <aside className={styles.aside}>
                    <Logo />
                    <MainNav />
                    {!isInternalUser ? <DeliveryCountDown /> : null}
                </aside>
                <main className={styles.main}>
                    <article className={styles.content}>
                        <div className={styles.stickyContent}>
                            <OperationalNotifications />
                            {truckBanner}
                            <ImpersonateBanner />
                            {!pageContent?.hideBreadcrumb && pageContent?.breadcrumb && (
                                <Breadcrumbs breadcrumb={pageContent.breadcrumb} isCarInfoBannerPresent={!!carInfoBannerData} />
                            )}
                            {carInfoBannerData && <CarInfoBanner {...carInfoBannerData} />}
                        </div>

                        {children}

                        <ErrorReport />
                    </article>

                    <Loader isVisible={!!loading || !!isRedirecting} className={styles.loader} delay={0.2} fixed />
                </main>
                <FtzPlusIntegration />
                <Footer className={styles.footer} />
                <Notifications />
                <Sprites />
                <SwitchCustomerOrBasket />
            </div>
        </div>
    );
};

export default StandardLayout;
