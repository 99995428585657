import React from 'react';
import useUser from '~/libs/use-user';
import ExpertToggle from '~/shared/expert-toggle/expert-toggle.component';
import { NavHeader, NavHeaderBackButton } from '~/features/navigation/shared/components';

import styles from './main-nav-back-button.module.scss';

export type MainNavBackButtonProps = {
    className?: string;
    type?: string;
    title?: string;
    onClick: () => void;
};

export function MainNavBackButton({ className, title, onClick, type }: MainNavBackButtonProps) {
    const { isInternalUser } = useUser();

    return (
        <NavHeader className={className}>
            {type === 'cars' && isInternalUser ? <ExpertToggle className={styles.mainNavBackButtonExpertToggle} /> : null}
            {title}
            <NavHeaderBackButton onClick={onClick} />
        </NavHeader>
    );
}
