import React, { useMemo } from 'react';
import cx from 'clsx';
import { Bff } from '~/models/bff';
import { NavHeader, NavHeaderBackButton, NavLink, NavLinkAnchor, NavScrollArea } from '~/features/navigation/shared/components';
import styles from './catalog-nav.module.scss';
import { useNavStore } from '~/features/navigation/shared/store';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { CdnImage } from '~/shared/image/cdn-image';

export function isLeafNode(link: Bff.ILink) {
    return !link.subLinks || !link.subLinks.length;
}

export function isWithImage(link: Bff.ILink) {
    return !!link.icon?.url;
}

export type CatalogNavProps = {
    menu: Bff.IIMenu;
    parent: Bff.ITopLink;
    path: string[];
};

export function CatalogNav({ menu, parent, path }: CatalogNavProps) {
    return <CatalogNavPane parent={parent} path={path} links={menu.links ?? []} />;
}

export type CatalogNavPaneProps = {
    links: Bff.ILink[];
    parent: Bff.ILink | Bff.ITopLink;
    path: string[];
    level?: number;
};

export function CatalogNavPane({ parent, links, path, level = 0 }: CatalogNavPaneProps) {
    const { isOpen, isBranch } = useNavStore((state) => ({
        isOpen: state.path.includes(parent.id),
        isBranch: state.path.length > 1 && level !== 0,
    }));

    return (
        <div
            className={cx(styles.catalogNav, {
                [styles.open]: isOpen,
                [styles.branch]: isBranch,
                [styles.current]: level === 1,
            })}
        >
            <CatalogNavPaneBackButton parent={parent} />
            {links.length && isOpen ? (
                <ul className={styles.catalogNavPaneList}>
                    {links.map((link) => (
                        <CatalogNavPaneLink key={link.id} link={link} level={level} path={[link.id, ...path]} />
                    ))}
                </ul>
            ) : null}
        </div>
    );
}

export type CatalogNavPaneBackButtonTypes = {
    parent: Bff.ILink | Bff.ITopLink;
    className?: string;
};

export function CatalogNavPaneBackButton({ parent }: CatalogNavPaneBackButtonTypes) {
    const { path, back } = useNavStore((state) => ({
        back: state.back,
        path: state.path,
    }));

    return (
        <NavHeader
            className={cx(styles.catalogNavPaneBackButton, {
                [styles.leaf]: !!parent.subLinks?.length,
            })}
        >
            {parent.title}
            <NavHeaderBackButton
                className={cx({
                    [styles.hidden]: path[0] !== parent.id,
                })}
                type={!!parent.subLinks?.length ? 'back' : 'close'}
                onClick={back}
            />
        </NavHeader>
    );
}

export type CatalogNavPaneLinkProps = {
    link: Bff.ILink;
    level: number;
    path: string[];
};

export function CatalogNavPaneLink({ link, level, path }: CatalogNavPaneLinkProps) {
    const router = useRouter();

    const { isActive, reset, close, set } = useNavStore((state) => ({
        isActive: state.path.includes(link.id),
        reset: state.reset,
        close: state.close,
        set: state.set,
    }));

    const handleClick = () => {
        if (!link.subLinks?.length) {
            reset();
            close();
            router.push(link.url);
            return;
        }

        set(path);
        return;
    };

    const pane = useMemo(() => {
        if (!link.subLinks || link.subLinks.length === 0) {
            // Not parent
            return null;
        }

        const incrementedLevel = level + 1;

        if (link.subLinks.every(isLeafNode) && link.subLinks.some(isWithImage)) {
            // Leaf node
            return <CatalogNavPaneLeaf parent={link} level={incrementedLevel} />;
        }

        return <CatalogNavPane parent={link} path={path} links={link.subLinks} level={incrementedLevel} />;
    }, [level, link, path]);

    return (
        <NavLink>
            <NavLinkAnchor
                className={cx(styles.catalogNavPaneLink, {
                    [styles.active]: isActive,
                })}
                onClick={handleClick}
            >
                {link.title}

                {!!link.subLinks?.length ? (
                    <svg className={styles.catalogNavLinkIcon}>
                        <use href="#sprite-chevron" />
                    </svg>
                ) : null}
            </NavLinkAnchor>
            {pane}
        </NavLink>
    );
}

export type CatalogNavPaneLeafProps = {
    parent: Bff.ILink;
    level: number;
};

export function CatalogNavPaneLeaf({ parent, level }: CatalogNavPaneLeafProps) {
    const { isOpen, isNested, isCurrent } = useNavStore((state) => ({
        isOpen: state.path.includes(parent.id),
        isNested: state.path.length > 2,
        isCurrent: state.path[0] === parent.id,
    }));

    return (
        <div
            className={cx(styles.catalogNavPaneLeaf, {
                [styles.open]: isOpen,
                [styles.nested]: isNested,
                [styles.current]: isCurrent,
            })}
        >
            <NavScrollArea>
                <CatalogNavPaneBackButton parent={parent} />
                {!!parent.subLinks?.length && isOpen ? (
                    <ul className={styles.catalogNavPaneLeafList}>
                        {parent.subLinks.map((link) => (
                            <CatalogNavPaneLeafLink key={link.id} link={link} />
                        ))}
                    </ul>
                ) : null}
            </NavScrollArea>
        </div>
    );
}

export type CatalogNavPaneLeafLinkProps = {
    link: Bff.ILink;
};

export function CatalogNavPaneLeafLink({ link }: CatalogNavPaneLeafLinkProps) {
    const router = useRouter();

    const { reset, close } = useNavStore((state) => ({
        isActive: state.path.includes(link.id),
        reset: state.reset,
        close: state.close,
        set: state.set,
    }));

    const handleClick = () => {
        reset();
        close();
        router.push(link.url);
    };

    return (
        <NavLink key={link.id}>
            <NavLinkAnchor className={styles.catalogNavLeafPaneLink} onClick={handleClick}>
                <div className={styles.catalogNavPaneImage}>
                    {link.icon?.url ? (
                        <CdnImage alt={link.title} src={link.icon.url} layout="fill" objectFit="cover" />
                    ) : (
                        <Image alt={link.title} src={'/images/placeholder.png'} layout="fill" objectFit="cover" />
                    )}
                </div>
                <span>{link.title}</span>
            </NavLinkAnchor>
        </NavLink>
    );
}
