import clsx from 'clsx';
import { FC, useRef } from 'react';
import { useLayoutState } from '~/context/layout.context';
import { useBreadcrumb } from '~/libs/queries/routing';
import useUser from '~/libs/use-user';
import Text from '~/shared/text/text.component';
import useTranslations from '../hooks/use-translations.hook';
import { Link } from '../link';
import Skeleton from '../skeleton';
import styles from './breadcrumbs.module.scss';
import { Bff } from '~/models/bff';

interface IProps {
    breadcrumb: Bff.IBreadcrumbViewModel;
    isCarInfoBannerPresent: boolean;
}

const Breadcrumbs: FC<IProps> = ({ breadcrumb, isCarInfoBannerPresent }) => {
    const breadcrumbsListEl = useRef(null);
    const translate = useTranslations();
    const { vehicle } = useLayoutState();
    const { profile, isInternalUser } = useUser();
    const isDark = Boolean(vehicle?.carId || vehicle?.licensePlate);

    return (
        <div
            className={clsx(styles.root, {
                [styles.blueWrapper]: isDark,
                [styles.isCarInfoBannerPresent]: isCarInfoBannerPresent,
            })}
        >
            <>
                {breadcrumb?.crumbs?.length ? (
                    <ul ref={breadcrumbsListEl} className={styles.breadcrumbs}>
                        {breadcrumb?.crumbs?.map((item, index) => {
                            if (typeof item.title !== 'string') {
                                return null;
                            }

                            const formattedTitle = item.title?.replace('##Search##', `${translate('common.search', 'Søgning')}:`);

                            return (
                                <li className={styles.breadcrumb} key={`${item.title}-${index}`}>
                                    {item.navigable ? (
                                        <Link title={formattedTitle} className={styles.link} href={item.url}>
                                            <span className={styles.linkText}>{formattedTitle}</span>
                                        </Link>
                                    ) : (
                                        <span className={styles.disabledLink}>
                                            <span className={styles.linkText}>{formattedTitle}</span>
                                        </span>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                ) : null}
            </>
            <div
                className={clsx(styles.profileInfo, {
                    [styles.expertModeLayout]: isInternalUser && vehicle,
                })}
            >
                <Text textStyle="bodySmall" nowrap>
                    {translate('customer.customerNumber', 'Kundenr')}: {profile?.customer_id}
                </Text>
                <Text textStyle="bodySmall" nowrap>
                    {translate('customer.userFirstName', 'Bruger')}: {profile?.username}
                </Text>
                <Text textStyle="bodySmall" nowrap>
                    {profile?.customer_address_name2}
                </Text>
            </div>
        </div>
    );
};

const SkeletonItem = ({ width, isDark }: { width: number; isDark: boolean }) => (
    <Skeleton isDark={isDark} style={{ height: '15px', width: `${width}px`, margin: '3px 10px 3px 0' }} />
);

const SkeletonFallBack = ({ isDark }: { isDark: boolean }) => (
    <ul className={styles.breadcrumbs}>
        <SkeletonItem width={80} isDark={isDark} />
        <SkeletonItem width={110} isDark={isDark} />
        <SkeletonItem width={100} isDark={isDark} />
    </ul>
);

export default Breadcrumbs;
