import React, { FC, useMemo } from 'react';
import { GetPropsCommonOptions, UseComboboxGetItemPropsOptions, UseComboboxGetMenuPropsOptions } from 'downshift';
import { IWithClassName } from '~/models/dev';
import useTranslations from '~/shared/hooks/use-translations.hook';
import styles from './search-results.module.scss';
import Text from '~/shared/text/text.component';
import { IQuickSearchItem } from '../../search-root.models';
import Skeleton, { getSkeletonRandom } from '~/shared/skeleton/skeleton.component';
import { MediaQuery, useMediaQueryDetect } from '~/shared/media-query-detect';
import SearchResultItem from './search-item/search-item.component';
import ErrorBox from '~/shared/error-box/error-box';

const SkeletonItem = () => {
    const width = useMemo(() => `${getSkeletonRandom(250)}px`, []);
    const isTablet = useMediaQueryDetect(MediaQuery.MD);
    return (
        <li className={styles.lisItemInactive}>
            <Skeleton
                isDark={!isTablet}
                style={{
                    width,
                    maxWidth: '100%',
                    height: '15px',
                }}
            />
        </li>
    );
};

interface IProps extends IWithClassName {
    // isOpen: boolean;
    parentValue: string;
    items: IQuickSearchItem[];
    getMenuProps: (options?: UseComboboxGetMenuPropsOptions, otherOptions?: GetPropsCommonOptions) => any;
    getItemProps: (options: UseComboboxGetItemPropsOptions<IQuickSearchItem>) => any;
    highlightedIndex: number;
    isFetchingVehicle?: boolean;
    isFetchingProducts?: boolean;
    clearSearchHistory?: () => void;
    isError: boolean;
}

const SearchResults: FC<IProps> = ({
    getMenuProps,
    items,
    getItemProps,
    parentValue,
    highlightedIndex,
    className,
    isFetchingVehicle,
    isFetchingProducts,
    clearSearchHistory,
    isError,
}) => {
    const translate = useTranslations();

    const titleText =
        parentValue.length || !items.length
            ? translate('search.labelSearchSuggestions', 'Søgeforslag')
            : translate('search.labelRecentSearches', 'Mine seneste søgninger');

    return (
        <div {...getMenuProps()} className={className}>
            {isError && <ErrorBox>{translate('search.fetchError', 'Der gik noget galt. Prøv igen senere')}</ErrorBox>}

            <Text tagName="h2" textStyle="monoMedium" className={styles.title}>
                {titleText}
            </Text>

            <ul className={styles.list}>
                {isFetchingVehicle && <SkeletonItem />}

                {items.map((item, index) => (
                    <li
                        {...getItemProps({ item, index })}
                        className={highlightedIndex === index ? styles.lisItemActive : styles.lisItemInactive}
                        key={`${item}${index}`}
                    >
                        <SearchResultItem item={item} isHighlighted={highlightedIndex === index} />
                    </li>
                ))}

                {isFetchingProducts && (
                    <>
                        <SkeletonItem />
                        <SkeletonItem />
                        <SkeletonItem />
                    </>
                )}
            </ul>
            {!parentValue && items.length ? (
                <button type="button" className={styles.clearHistoryBtn} onClick={clearSearchHistory}>
                    <Text textStyle="bodySmall" fontWeight="semiBold">
                        {translate('search.clearHistory', 'Ryd historik')}
                    </Text>
                </button>
            ) : null}
        </div>
    );
};

export default SearchResults;
