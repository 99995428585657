import { IPostData } from '~/widgets/car-info-banner/components/windshield-exchange/windshield-exchange.component';
import { endpoints, createUrl, LocaleOptions } from '../service-endpoint';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';

export const CarWindShieldEngineRequest = (data: IPostData, locale: string, user: Session | null) => {
    const url = createUrl(
        {
            endpoint: endpoints.vehicles.windShieldChange,
            localeOption: LocaleOptions.path,
        },
        locale,
    );

    return apiClient.auth(user).postJSON(url, data);
};
