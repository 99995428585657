import { useMemo } from 'react';
import { Bff } from '~/models/bff.d';
import { useStaticContent } from '~/libs/queries/bff';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { MediaQuery, useMediaQueryDetect } from '~/shared/media-query-detect';

const staticSettingMenuId = 'settingsMenu';

export default function useMegaMenu() {
    const translate = useTranslations();
    const { data, isLoading, error } = useStaticContent();
    const isMobile = useMediaQueryDetect(MediaQuery.SM);

    const menuWithSettings = useMemo(() => {
        const immutable = { ...data };
        if (isMobile && data && !data?.megaMenu?.links?.some((x) => x.id === staticSettingMenuId) && !error) {
            const settingsMenuItem: Bff.ITopLink = {
                menuGroups: [
                    {
                        type: 'Standard' as Bff.MenuType,
                        data: {
                            links: data.settingsArea?.map((setting: any) => ({
                                id: setting.id,
                                url: setting.url,
                                openInNewWindow: setting.openInNewWindow,
                                title: setting.title,
                                icon: setting.openInNewWindow
                                    ? {
                                          url: '/svg/link-alt.svg',
                                          altText: 'Eksternt link',
                                          size: '12px',
                                      }
                                    : undefined,
                                spriteIcon: '',
                                subLinks: [],
                                documentType: 'megamenuLink',
                            })),
                        },
                        title: translate('header.settings', 'Indstillinger'),
                    },
                ],
                displayMode: 'small' as Bff.DisplayMode,
                alternateIcon: undefined,
                id: staticSettingMenuId,
                url: '#',
                openInNewWindow: false,
                title: translate('header.settings', 'Indstillinger'),
                icon: undefined,
                spriteIcon: 'static-settings',
                subLinks: [],
                documentType: 'topLink1',
            };

            immutable?.megaMenu?.links?.push(settingsMenuItem);
        }
        if (!isMobile && immutable?.megaMenu?.links?.at(-1)?.id === 'settingsMenu') immutable?.megaMenu?.links?.pop();
        return immutable;
    }, [data?.megaMenu, data?.settingsArea, error, isMobile, translate]);

    return {
        data: menuWithSettings,
        isLoading,
        error,
    };
}
