import { useState } from 'react';
import { useStaticContent } from '~/libs/queries/bff';
import DamCalculatorForDam from '~/shared/dam-calculator/calculator/calculator-dam-for-dam.component';
import DamPrCalculatorForDate from '~/shared/dam-calculator/calculator/calculator-dam-for-date.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Toggle from '~/shared/toggle/toggle.component';
import styles from './calculator-dam.module.scss';

const Calculator = () => {
    const translate = useTranslations();
    const { data: staticContent } = useStaticContent();
    const [isCalculatorForDate, setIsCalculatorForDate] = useState(false);

    return (
        <div className={styles.container}>
            <p>
                {translate(
                    'damCalculator.damLongerThanFive',
                    'Hvis DAM/PR nummeret er længere end 5 karakterer, skal de to sidste karakterer fjernes. For yderligere info klik',
                )}
                <a target="_blank" href={staticContent?.urlMappings?.damDocumentation}>
                    {translate('damCalculator.here', 'her')}
                </a>
            </p>
            <Toggle
                className={styles.toggle}
                isOn={isCalculatorForDate}
                onClick={() => setIsCalculatorForDate(!isCalculatorForDate)}
                onIcon={'dam'}
                onText={translate('damCalculator.damForDate', 'DAM/PR til dato')}
                offIcon={'calendar'}
                offText={translate('damCalculator.dateForDam', 'Dato till DAM/PR')}
            />
            {isCalculatorForDate ? <DamCalculatorForDam /> : <DamPrCalculatorForDate />}
        </div>
    );
};

export default Calculator;
