import useTranslations from '~/shared/hooks/use-translations.hook';
import { useForm } from 'react-hook-form';
import styles from '~/shared/dam-calculator/calculator/calculator-dam.module.scss';
import Input from '~/shared/form-elements/input/input.component';
import clsx from 'clsx';
import Button from '~/shared/buttons/button/button.component';
import React, { useEffect, useState } from 'react';
import Loader from '~/shared/loader/loader.component';
import { useDamCalculatorDate } from '~/libs/queries/bff/hooks/use-dam-calculator-date';

interface ICalcProps {
    damPrNumber?: number;
}

const DamPrCalculatorForDate = () => {
    const translate = useTranslations();
    const [damPrNumber, setDamPrNumber] = useState<number>(0);
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
    } = useForm<ICalcProps>();
    const { isError, isLoading, data, refetch } = useDamCalculatorDate(damPrNumber);

    const onSubmit = async (dataFromCalc: ICalcProps) => {
        if (dataFromCalc.damPrNumber !== undefined) {
            setDamPrNumber(dataFromCalc.damPrNumber);
        }
    };

    const formatDate = (isoDateString: string | undefined) => {
        if (!isoDateString || isoDateString === '1976-11-08T00:00:00') {
            // Default date from value 0
            return '';
        }
        const date = new Date(isoDateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // January is 0
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        if (damPrNumber) {
            refetch();
        }
    }, [damPrNumber]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.inputWrapper}>
                <p className={styles.inputText}>{translate('damCalculator.enterDam', 'Indtast DAM/PR nr. her:')}</p>
                <Input
                    {...register('damPrNumber', { required: true })}
                    className={clsx(styles.input, { [styles.withError]: !!errors.damPrNumber || isError })}
                    errorMessage={
                        (errors.damPrNumber && translate('damCalculator.formCantBeEmpty', 'formularen må ikke være tom')) ||
                        (isError && translate('common.somethingWentWrong', 'Der gik noget galt.'))
                    }
                />
                <Button type="submit" disabled={!isDirty}>
                    {translate('damCalculator.search', 'Søg')}
                </Button>
                {isLoading ? <Loader size={'25px'} /> : null}
            </div>
            <p>
                {translate('damCalculator.carWasProducedOn', 'Bilen er produceret den: ')}
                <b>{isLoading ? null : formatDate(data?.created)}</b>
            </p>
        </form>
    );
};

export default DamPrCalculatorForDate;
