import React from 'react';
import { Bff } from '~/models/bff';
import { DynamicWidget } from '../dynamic-widget';
import { ModuleRow } from '~/features/primitives/modules';
import styles from './styled.module.scss';

export function DynamicFooterWidget({ widget }: { widget: Bff.IFooterModule }) {
    if (!widget.module) {
        return null;
    }

    return (
        <div
            className={widget.backgroundColor && styles.dynamicFooterWidgetContainer}
            style={{ '--bg-color': widget.backgroundColor } as React.CSSProperties}
        >
            <ModuleRow className={styles.dynamicFooterWidget} spacing={widget.module.spacingBottom}>
                <DynamicWidget widget={widget.module} />
            </ModuleRow>
        </div>
    );
}
