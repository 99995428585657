import React, { FC, useEffect } from 'react';
import clsx from 'clsx';
import RouterLink from 'next/link';
import { IMenuGroup } from '../../navigation';
import useTranslations from '~/shared/hooks/use-translations.hook';
import useNavigationLayout from '~/context/use-navigation.context';
import styles from './latest-vehicles.module.scss';
import Loader from '~/shared/loader/loader.component';
import ErrorBox from '~/shared/error-box/error-box';
import { Link } from '~/shared/link';
import { useLatestVehicles } from '~/libs/queries/vehicles/hooks/use-latest-vehicles';

const LatestVehicles: FC<IMenuGroup> = () => {
    const { setNavState } = useNavigationLayout();
    const translate = useTranslations();

    const { data, isLoading, isError, refetch } = useLatestVehicles();

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = () => {
        setNavState({
            isOpen: false,
        });
    };

    if (isError) {
        return (
            <div className={styles.root}>
                <ErrorBox className={styles.errorBox}>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className={styles.root}>
                <Loader className={styles.loader} />
            </div>
        );
    }

    return (
        <div className={styles.root}>
            {data && !data?.latestVehicles?.length && (
                <p className={styles.empty}>{translate('latestVehicles.emptyMessage', 'Her finder du de seneste biler, der er blevet søgt på')}</p>
            )}
            <div className={styles.table}>
                {data?.latestVehicles?.length && (
                    <div className={styles.tableRow}>
                        <div className={clsx(styles.tableCell, styles.tableHead)}>{translate('latestVehicles.model', 'Model')}</div>
                        <div className={clsx(styles.tableCell, styles.tableHead)}>{translate('latestVehicles.regVin', 'Reg nummer')}</div>
                    </div>
                )}

                {data?.latestVehicles?.map(({ url, value, model }, i) => (
                    <RouterLink prefetch={false} href={url as string} key={`${url}-${value}-${i}`} passHref>
                        <Link className={styles.tableRow} onClick={handleClick}>
                            <span className={styles.tableCell}>{model}</span>

                            <span className={clsx(styles.tableCell, styles.tableCellRegNumber)}>{value}</span>
                        </Link>
                    </RouterLink>
                ))}
            </div>
        </div>
    );
};

export default LatestVehicles;
