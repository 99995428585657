import React from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { Bff } from '~/models/bff';
import { MainNavOverlay } from '~/features/navigation/main-nav/components/main-nav-overlay/main-nav-overlay.component';
import { useNavStore } from '~/features/navigation/shared/store';
import styles from './main-nav-parent-link.module.scss';
import { GA4LatestCars } from '~/libs/ga4';

export type MainNavParentLinkProps = {
    link: Bff.ITopLink;
    onClick?: () => void;
};

/**
 * A top level link in the mega menu sidebar
 */
export function MainNavParentLink({ link, onClick }: MainNavParentLinkProps) {
    const router = useRouter();

    const { set, selected, path, close, reset } = useNavStore((state) => ({
        set: state.set,
        selected: state.path.includes(link.id),
        path: state.path,
        reset: state.reset,
        close: state.close,
    }));

    const doOpenLink = () => {
        if (link.openInNewWindow) {
            window.open(link.url, '_blank');
            return;
        }

        router.push(link.url);
    };

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        if (!link.subLinks?.length && link.url) {
            doOpenLink();

            reset();
            close();

            return;
        }

        if (path.includes(link.id)) {
            set([]);
        } else {
            set([link.id]);
        }

        onClick?.();
    };

    return (
        <li key={link.id}>
            <a
                role="button"
                onClick={handleClick}
                className={clsx(styles.mainNavParentLink, {
                    [styles.selected]: selected,
                })}
            >
                {link?.spriteIcon && (
                    <svg className={styles.mainNavParentLinkIcon}>
                        <use href={`#sprite-${link.spriteIcon}`} />
                    </svg>
                )}

                {link.title}

                {!!link.subLinks?.length ? (
                    <svg className={styles.mainNavParentLinkArrow}>
                        <use href="#sprite-chevron" />
                    </svg>
                ) : null}
            </a>

            {link?.menuGroups?.length ? <MainNavOverlay link={link} /> : null}
        </li>
    );
}

export type MainNavLatestCarsParentLinkProps = {
    link: Bff.ITopLink;
};

export function MainNavLatestCarsParentLink({ link }: MainNavLatestCarsParentLinkProps) {
    const handleClick = () => {
        GA4LatestCars();
    };

    return <MainNavParentLink link={link} onClick={handleClick} />;
}
