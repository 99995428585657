import { useVehicleInvoices } from '~/libs/queries/vehicles/hooks/use-vehicle-invoices';
import { Vehicles as VehicleTypes } from '~/models/vehicles.d';
import ErrorBox from '~/shared/error-box/error-box';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Link } from '~/shared/link';
import Loader from '~/shared/loader/loader.component';
import Text from '~/shared/text/text.component';
import InvoiceDetails from './invoice-details/invoice-details.component';
import styles from './styled.module.scss';

import { CLIENT_QUERY_OPTIONS } from '~/constants/query';

interface IPreviousOrdersProps {
    licensePlate?: VehicleTypes.ILicensePlate;
    cmsCarId?: string;
    vin?: string;
    closeModal?: () => void;
    licensePlateOrVin: string;
}

export default function PreviousOrders({ licensePlate, cmsCarId, vin, closeModal, licensePlateOrVin }: IPreviousOrdersProps) {
    const translate = useTranslations();
    const { data, isLoading, isError } = useVehicleInvoices(licensePlate?.number as string);

    if (isLoading) return <Loader padding="50px 0" />;
    if (isError) {
        return <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>;
    }

    return (
        <>
            <div className={styles.header}>
                <Text secondary fontWeight="semiBold" textStyle="bodySmall">
                    {data?.invoices?.length} {translate('common.results', 'resultater')}
                </Text>
                <Link href={`${data?.allInvoicesUrl}?${CLIENT_QUERY_OPTIONS.inPageSearch}=${licensePlateOrVin}`}>
                    {translate('overview.seeAllOrders', 'Se alle ordrer')}
                </Link>
            </div>
            {data?.invoices?.map((invoice) => (
                <InvoiceDetails
                    key={invoice?.invoiceId}
                    invoice={invoice}
                    licensePlate={licensePlate}
                    cmsCarId={cmsCarId}
                    vin={vin}
                    closeModal={closeModal}
                />
            ))}
        </>
    );
}
