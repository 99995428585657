import React, { FC, useCallback, useState } from 'react';
import { LayoutActionTypes, useLayoutDispatch, useLayoutState } from '~/context/layout.context';
import useNavigationLayout from '~/context/use-navigation.context';
import Button from '~/shared/buttons/button/button.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Modal } from '~/features/primitives/modal';
import Skeleton from '~/shared/skeleton';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import styles from './department-change.module.scss';
import DepartmentsList from './departments-list/departments-list.component';
import { useDepartmentsByRegions, useDepartmentsByRegionsName } from '~/libs/queries/users/hooks/use-departments-by-regions';

const DepartmentChange: FC = () => {
    const { setNavState } = useNavigationLayout();
    const [isOpen, setOpen] = useState(false);
    const { selectedDepartment } = useLayoutState();
    const dispatch = useLayoutDispatch();
    const translate = useTranslations();
    const { isLoading: isLoadingDepartments, data: departmentRegions } = useDepartmentsByRegions();
    const userDepartmentName = useDepartmentsByRegionsName(departmentRegions);

    const handleOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setOpen(false);
    }, []);

    return (
        <>
            <div className={styles.root}>
                <Text className={styles.title}>{translate('departmentChange.yourDepartment', 'Din afdeling')}</Text>
                <div className={styles.selector}>
                    {selectedDepartment ? (
                        <button
                            className={styles.closeBtn}
                            type="button"
                            onClick={() => {
                                dispatch({
                                    type: LayoutActionTypes.SetSelectedDepartment,
                                    payload: null,
                                });
                            }}
                        >
                            <Text title={selectedDepartment.title} overflowEllipsis fontWeight="semiBold">
                                {selectedDepartment.title}
                            </Text>
                            <Svg className={styles.closeBtnIcon} name="close" thick />
                        </button>
                    ) : (
                        <Text title={userDepartmentName as string} overflowEllipsis fontWeight="semiBold" className={styles.departmentText}>
                            {isLoadingDepartments ? <Skeleton isDark style={{ height: '15px', width: '100px' }} /> : userDepartmentName}
                        </Text>
                    )}
                    <Button
                        className={styles.changeButton}
                        buttonSize="small"
                        onClick={() => {
                            setOpen(true);
                            setNavState({ isOpen: false });
                        }}
                        buttonStyle="secondaryLight"
                    >
                        {translate('departmentChange.changeBtn', 'Skift')}
                    </Button>
                </div>
            </div>
            <Modal
                title={translate('departmentChange.changeDepartment', 'Skift Afdeling')}
                open={isOpen}
                position="left"
                onOpenChange={handleOpenChange}
            >
                <DepartmentsList onClose={() => setOpen(false)} />
            </Modal>
        </>
    );
};

export default DepartmentChange;
