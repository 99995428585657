import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from '~/services/api-client';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import useUser from '~/libs/use-user';
import { queryKeys } from '~/libs/queries/query-keys';

interface DamCalculatorDateResponse {
    created: string;
}

export function useDamCalculatorDate(damNumber: number) {
    const { locale } = useRouter();
    const { vehicles } = useEndpoints();
    const { isLoggedIn } = useUser();
    const { createUrl } = useEndpoints();
    const { user } = useUser();

    const url = createUrl({
        endpoint: `${vehicles.getCarAgeFromDamPrNumber}/${damNumber}`,
        localeOption: LocaleOptions.path,
    });

    return useQuery<DamCalculatorDateResponse, Error>({
        queryKey: queryKeys.vehicle.damCalculator(locale),
        queryFn: async () => {
            return apiClient.auth(user).get(url);
        },
        enabled: isLoggedIn,
    });
}
