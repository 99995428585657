import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { LayoutActionTypes, useLayoutDispatch, useLayoutState } from '~/context/layout.context';
import { queryKeys } from '~/libs/queries/query-keys';
import useUser from '~/libs/use-user';
import { Bff } from '~/models/bff';
import { apiClient } from '~/services/api-client';
import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { BFF_GET_OPERATIONAL_NOTIFICATION } from '../constants';

export function useOperationalNotifications() {
    const { isLoggedIn, user } = useUser();
    const { locale } = useRouter();
    const { createUrl } = useEndpoints();

    const url = createUrl({
        endpoint: BFF_GET_OPERATIONAL_NOTIFICATION,
        localeOption: LocaleOptions.queryAlt,
    });

    return useQuery<Bff.INotificationBannerResult, Error>({
        queryKey: queryKeys.bff.notification(locale),
        queryFn: async () => {
            return apiClient.auth(user).get(url);
        },
        enabled: isLoggedIn,
    });
}

export function useOperationalNotificationActions(operational?: Bff.INotificationBannerResult) {
    const { showOperationalNotifications } = useLayoutState();
    const dispatch = useLayoutDispatch();

    const isActive = !!operational && operational?.status !== null && showOperationalNotifications;

    const setActive = (state: boolean) => {
        dispatch({
            type: LayoutActionTypes.setOperationalNotifications,
            payload: state,
        });
    };

    return {
        isActive,
        setActive,
    };
}
