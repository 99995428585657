import React, { useMemo } from 'react';
import Text from '~/shared/text/text.component';
import { NotificationBanner } from '~/shared/notification-banner';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { useTopmotiveMultiMatchInfo } from '~/widgets/car-info-banner/use-car-info-banner.hook';
import Link from 'next/link';

import styles from './truck-multi-match-banner.module.scss';

export function TruckMultiMatchBanner() {
    const translate = useTranslations();

    const { data: multiMatchInfo } = useTopmotiveMultiMatchInfo();

    const label = useMemo(() => {
        const [leading, backing] = translate('topmotive.multiMatch.text', 'Nummerpladen matchede også bil. {{link}}').split('{{link}}');

        if (!multiMatchInfo) {
            return (
                <>
                    {leading}
                    <a className={styles.truckMultiMatchBannerAnchor}>
                        {translate('topmotive.multiMatch.link', 'Gå til personbil {{identifier}}').replace('{{identifier}}', '')}
                    </a>
                    {backing}
                </>
            );
        }

        return (
            <>
                {leading}
                <Link href={multiMatchInfo.url ?? ''} passHref>
                    <a className={styles.truckMultiMatchBannerAnchor}>
                        {translate('topmotive.multiMatch.link', 'Gå til {{identifier}}').replace('{{identifier}}', multiMatchInfo.title)}
                    </a>
                </Link>
                {backing}
            </>
        );
    }, [multiMatchInfo, translate]);

    if (!multiMatchInfo) {
        return null;
    }

    return (
        <NotificationBanner color="dark-green">
            <Text textAlign="center" textStyle="body" color="lightColor">
                {label}
            </Text>
        </NotificationBanner>
    );
}
