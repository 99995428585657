import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { Orders as OrdersTypes } from '~/models/orders.d';
import { useLayoutState } from '~/context/layout.context';
import { queryKeys } from '~/libs/queries/query-keys';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from '~/services/api-client';
import { useRouter } from 'next/router';
import useUser from '~/libs/use-user';

export function useVehicleInvoices(licensePlateOrVin: string) {
    const { selectedDepartment } = useLayoutState();
    const { locale } = useRouter();
    const { user } = useUser();

    const { orders, createUrl } = useEndpoints();
    const url = createUrl({
        endpoint: `${orders.invoices}/by-vehicle`,
        localeOption: LocaleOptions.path,
        query: {
            licensePlateOrVin,
            departmentId: selectedDepartment?.id,
        },
    });

    return useQuery<OrdersTypes.IVehicleInvoicesList, Error>({
        queryKey: queryKeys.vehicle.vehicleInvoices(locale, licensePlateOrVin),
        queryFn: async () => {
            return apiClient.auth(user).get(url);
        },
        enabled: !!licensePlateOrVin,
    });
}
