import React, { memo } from 'react';
import Skeleton from '~/shared/skeleton';
import styles from './main-nav-skeleton.module.scss';

export type MainNavSkeletonItemProps = {
    width: number;
};

export function MainNavSkeletonItem({ width }: MainNavSkeletonItemProps) {
    return (
        <li>
            <button type="button" className={styles.mainNavSkeletonItem} disabled aria-label="Disabled button">
                <Skeleton isDark style={{ height: '15px', width }} />
            </button>
        </li>
    );
}

export function MainNavSkeleton() {
    return (
        <>
            <MainNavSkeletonItem width={105} />
            <MainNavSkeletonItem width={60} />
            <MainNavSkeletonItem width={85} />
            <MainNavSkeletonItem width={120} />
            <MainNavSkeletonItem width={100} />
            <MainNavSkeletonItem width={90} />
            <MainNavSkeletonItem width={95} />
        </>
    );
}

export const MemoizedMainNavSkeleton = memo(MainNavSkeleton);
