import React, { FC } from 'react';
import clsx from 'clsx';
import { IWithClassName } from '~/models/dev';
import IconBadge from '~/shared/icon-badge';

import styles from './disc-icon.module.scss';

interface IDiscIconProps extends IWithClassName {
    badge?: string | number;
    size?: number;
    fontSize?: number;
}

const DiscIcon: FC<IDiscIconProps> = ({ badge, children, size = 35, className }) => (
    <div className={clsx(styles.disc, className)} style={{ width: size, height: size }}>
        <IconBadge className={styles.badge} value={badge as string} />
        {children}
    </div>
);

export default DiscIcon;
