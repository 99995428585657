import { FC, MouseEventHandler, useEffect, useState } from 'react';

import clsx from 'clsx';
import { INotification, NotificationLevel } from '../notifications.d';

import { Svg } from '~/shared/svg';

import { LayoutActionTypes, useLayoutDispatch } from '~/context/layout.context';
import styles from './notification.module.scss';
import Heading from '~/shared/heading/heading.component';

const notificationLevelModifiers = {
    [NotificationLevel.SUCCESS]: styles.success,
    [NotificationLevel.INFO]: styles.info,
    [NotificationLevel.WARN]: styles.warn,
    [NotificationLevel.ERROR]: styles.error,
};

const notificationLevelIcons = {
    [NotificationLevel.SUCCESS]: 'badge-confirm',
    [NotificationLevel.WARN]: 'badge-warning',
    [NotificationLevel.ERROR]: 'warning',
    [NotificationLevel.INFO]: 'info',
};

export interface INotificationProps {
    notification: INotification;
}

const Notification: FC<INotificationProps> = ({ notification }) => {
    const [isActive, setActive] = useState(false);
    const dispatch = useLayoutDispatch();

    let timerId: number;
    useEffect(() => {
        setActive(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        timerId = window.setTimeout(handleClose, 10000);
        return () => {
            window.clearTimeout(timerId);
        };
    }, []);

    const { text, subText, notificationLevel } = notification;

    const handleClose = () => {
        setActive(false);
        window.setTimeout(() => {
            dispatch({
                type: LayoutActionTypes.RemoveToast,
                payload: notification.timeStamp,
            });
        }, 300);
    };

    const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();
        handleClose();
    };

    return (
        <div
            className={clsx(notificationLevelModifiers[notificationLevel], {
                [styles.notificationActive]: isActive,
                [styles.notification]: !isActive,
            })}
        >
            <button aria-label="Close" onClick={handleClick} type="button" className={styles.closeBtn}>
                <Svg name="close" className={styles.close} />
            </button>
            <div className={styles.contentWrapper}>
                <Svg className={notificationLevelModifiers[notificationLevel]} name={notificationLevelIcons[notificationLevel]} />
                <div className={styles.textWrapper}>
                    {subText ? <Heading tagName="h4">{text}</Heading> : <span>{text}</span>}
                    {subText && <span>{subText}</span>}
                </div>
            </div>
        </div>
    );
};

export default Notification;
