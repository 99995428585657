import { create } from 'zustand';

export type NavStore = {
    isOpen: boolean;
    toggle: () => void;
    close: () => void;
    back: () => void;
    path: string[];
    add: (id: string) => void;
    remove: (id: string) => void;
    set: (ids: string[]) => void;
    reset: () => void;
};

export const useNavStore = create<NavStore>((set) => ({
    isOpen: false,
    path: [],
    toggle: () =>
        set((state) => {
            return {
                ...state,
                isOpen: !state.isOpen,
            };
        }),
    close: () =>
        set((state) => ({
            ...state,
            isOpen: false,
        })),
    back: () =>
        set((state) => ({
            ...state,
            path: (() => {
                const newPath = [...state.path];

                newPath.shift();

                return newPath;
            })(),
        })),
    add: (id) => {
        set((state) => ({
            ...state,
            // The appended sub navigation will always be the first element in the path array.
            path: [id, ...state.path],
        }));
    },
    remove: (id) => set((state) => ({ ...state, path: state.path.filter((item) => item !== id) })),
    set: (ids: string[]) => {
        set((state) => ({ ...state, path: ids }));
    },
    reset: () => {
        set((state) => ({
            ...state,
            path: [],
        }));
    },
}));

// Selector for getting overlay open state
export function getOverlayVisibility(state: NavStore) {
    return {
        isOverlayVisible: state.path.length > 0,
    };
}
