import React, { FC, useMemo } from 'react';
import styles from './search-item.module.scss';
import { IQuickSearchItem } from '../../../search-root.models';
import { Products as ProductTypes } from '~/models/products.d';
import { Svg } from '~/shared/svg';
import useTranslations from '~/shared/hooks/use-translations.hook';

interface IProps {
    item: IQuickSearchItem;
    isHighlighted: boolean;
}

const SearchResultItem: FC<IProps> = ({ item }) => {
    const translate = useTranslations();

    const iconName = useMemo(() => {
        switch (item.type) {
            case 'Vehicle':
                if (item.vehicleClass === 0 || item.vehicleClass === 1) {
                    return 'car';
                }

                return 'truck';

            case ProductTypes.QuickSearchResultType.Variant:
            case ProductTypes.QuickSearchResultType.Product: {
                return 'shipping';
            }
            case ProductTypes.QuickSearchResultType.UniversalCategory:
            case ProductTypes.QuickSearchResultType.SparePartCategory: {
                return 'category';
            }
            case ProductTypes.QuickSearchResultType.CrossNumberMatch:
            case ProductTypes.QuickSearchResultType.SearchSuggestion: {
                return 'search';
            }
            default:
                return 'search';
        }
    }, [item]);

    const text = useMemo(() => {
        switch (item?.type) {
            case 'Vehicle':
            case ProductTypes.QuickSearchResultType.Product:
            case ProductTypes.QuickSearchResultType.SearchSuggestion:
            case ProductTypes.QuickSearchResultType.SparePartCategory:
            case ProductTypes.QuickSearchResultType.UniversalCategory:
            case ProductTypes.QuickSearchResultType.Variant:
                return (
                    <>
                        <span className={styles.mainText}>{item?.text}</span>
                        {item?.secondaryText && <span className={styles.secondaryText}>{item.secondaryText}</span>}
                    </>
                );
            case ProductTypes.QuickSearchResultType.SearchResultPage:
                return (
                    <>
                        <span className={styles.resultPageText}>{item?.text}</span>
                    </>
                );
            case ProductTypes.QuickSearchResultType.CrossNumberMatch: {
                return (
                    <>
                        <span className={styles.mainText}>{item?.text}</span>
                        <span className={styles.secondaryText}>{translate('search.crossNumber', 'Kryds nr. søgning')}</span>
                    </>
                );
            }

            default:
                return null;
        }
    }, [item.secondaryText, item?.text, item?.type, translate]);

    return (
        <div className={styles.item}>
            <Svg name={iconName} className={styles.icon} />
            <span>{text}</span>
        </div>
    );
};

export default SearchResultItem;
