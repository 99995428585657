import React from 'react';
import cx from 'clsx';
import styles from './nav-scroll-area.module.scss';

export type NavScrollAreaProps = {
    className?: string;
    children?: React.ReactNode | React.ReactNode[] | null;
};

export function NavScrollArea({ className, children }: NavScrollAreaProps) {
    return <div className={cx(styles.navScrollArea, className)}>{children}</div>;
}
