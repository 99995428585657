import React, { FC } from 'react';
import { Bff } from '~/models/bff';
import { IMenuGroup } from '../../navigation.d';
import { Link } from '~/shared/link';
import { getInitials } from '~/helpers';
import DiscIcon from '~/shared/disc-icon/disc-icon.component';
import useNavigationLayout from '~/context/use-navigation.context';

import styles from './featured.module.scss';

const Featured: FC<IMenuGroup> = ({ data, title }) => {
    const { setNavState } = useNavigationLayout();

    return (
        <div className={styles.featured}>
            {title && <p className={styles.headline}>{title}</p>}

            <div className={styles.listWrapper}>
                <ul className={styles.list}>
                    {data?.links?.map((link: Bff.ILink, index: number) => (
                        <li className={styles.item} key={`${link.id}-${index}`}>
                            <Link
                                prefetch={false}
                                href={link?.url}
                                className={styles.link}
                                onClick={() =>
                                    setNavState({
                                        isOpen: false,
                                    })
                                }
                            >
                                <DiscIcon size={31} className={styles.icon}>
                                    {getInitials(link.title)}
                                </DiscIcon>

                                <span>{link.title}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Featured;
