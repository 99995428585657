import React from 'react';
import cx from 'clsx';
import styles from './nav-header.module.scss';

export type NavHeaderProps = {
    className?: string;
    children?: React.ReactNode | React.ReactNode[] | null;
};

export function NavHeader({ className, children }: NavHeaderProps) {
    return <div className={cx(styles.navHeader, className)}>{children}</div>;
}

export type NavHeaderBackButton = {
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    type?: 'back' | 'close';
};

export function NavHeaderBackButton({ className, onClick, type = 'close' }: NavHeaderBackButton) {
    return (
        <button aria-label="Back" type="button" className={cx(styles.navHeaderBackButton, className)} onClick={onClick}>
            <span className={styles.navHeaderBackButtonIconContainer}>
                <svg className={styles.navHeaderBackButtonIcon}>
                    <use href="#sprite-chevron" className={styles.iconChevron} />
                    {type === 'back' ? (
                        <use href={'#sprite-chevron-left'} className={styles.iconClose} />
                    ) : (
                        <use href={`#sprite-close`} className={styles.iconClose} />
                    )}
                </svg>
            </span>
        </button>
    );
}
