import clsx from 'clsx';
import { LayoutActionTypes, useLayoutDispatch } from '~/context/layout.context';
import useUser from '~/libs/use-user';
import { useStaticContent } from '~/libs/queries/bff';
import { IWithClassName } from '~/models/dev';
import Heading from '~/shared/heading/heading.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { CdnImage } from '~/shared/image/cdn-image';
import { CMSLink, Link } from '~/shared/link';
import { DynamicFooterWidget } from '~/widgets/dynamic-footer-widget';
import styles from './footer.module.scss';
import { useCMSPage } from '~/libs/queries/bff';
import { ModuleList } from '~/features/primitives/modules';

export default function Footer({ className }: IWithClassName) {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();

    const { isLoggedIn, isInternalUser } = useUser();

    const { data: staticContent } = useStaticContent();
    const { data: page } = useCMSPage();

    const handleClickErrorReport = () => {
        dispatch({
            type: LayoutActionTypes.OpenErrorReport,
            payload: {},
        });
    };

    if (!staticContent?.footer) {
        return null;
    }

    return (
        <>
            <footer className={clsx(styles.footer, className)}>
                {staticContent?.footer?.modules && page?.type !== 'P020HomePage' ? (
                    <ModuleList className={styles.moduleListFooter} gap="none" width="full">
                        {staticContent.footer.modules.map((widget, id) => (
                            <DynamicFooterWidget key={id} widget={widget} />
                        ))}
                    </ModuleList>
                ) : null}

                <div className={clsx('container-fluid', styles.content)}>
                    <section>
                        <Heading tagName="h4" className={styles.sectionTitle}>
                            {translate('footer.addressTitle', 'FTZ Autodele & Værktøj A/S')}
                        </Heading>

                        <p className={styles.text}>
                            {staticContent?.footer?.contact?.streetAddress}
                            <br />
                            {staticContent?.footer?.contact?.zipCode} {staticContent?.footer?.contact?.city}
                        </p>

                        <p className={styles.text}>
                            {`${translate('common.phone', 'Telefon')}: `}
                            <Link href={`tel:${staticContent?.footer?.contact?.phone}`}>{staticContent?.footer?.contact?.phone}</Link>
                            <br />
                            {translate('common.fax', 'Telefon')}: {staticContent?.footer?.contact?.fax}
                            <br />
                            {`${translate('common.email', 'E-mail')}: `}
                            <Link href={`mailto:${staticContent?.footer?.contact?.email}`}>{staticContent?.footer?.contact?.email}</Link>
                            <br />
                            {translate('common.cvrNumber', 'CVR-nummer')}: {staticContent?.footer?.contact?.cvr}
                        </p>
                    </section>

                    <section>
                        <Heading tagName="h4" className={styles.sectionTitle}>
                            {translate('footer.linksTitle', 'Genveje')}
                        </Heading>

                        {staticContent?.footer?.links?.map(
                            (link, i) =>
                                link && (
                                    <CMSLink className={styles.link} link={link} key={i} prefetch={false}>
                                        {link.title}
                                    </CMSLink>
                                ),
                        )}

                        {/* TODO: Use check from BE to render the link */}
                        {isLoggedIn && (
                            <Link className={styles.link} onClick={handleClickErrorReport}>
                                {translate('errorReport.reportError', 'Meld fejl')}
                            </Link>
                        )}
                    </section>

                    <section>
                        {staticContent?.footer?.image?.url && (
                            <CdnImage
                                priority
                                sizes="500px"
                                width="500"
                                height="500"
                                src={staticContent?.footer?.image.url}
                                alt={staticContent?.footer?.image.altText}
                                layout="responsive"
                                objectFit="contain"
                            />
                        )}
                    </section>
                </div>
            </footer>
        </>
    );
}
