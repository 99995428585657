import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './nav-counter.module.scss';

interface ITimeLeft {
    hours: number;
    minutes: number;
    seconds: number;
}

const pad = (num: number): string => {
    if (num <= 0) return '00';
    return num <= 9 ? `0${num.toString()}` : num.toString();
};

const getTimeLeft = (from: Date, to: Date): ITimeLeft => {
    const diff = to.getTime() - from.getTime();

    return {
        hours: Math.floor(diff / (1000 * 60 * 60)),
        minutes: Math.floor((diff / 1000 / 60) % 60),
        seconds: Math.floor((diff / 1000) % 60),
    };
};

interface INavCounterProps {
    to: Date;
}

const NavCounter = ({ to }: INavCounterProps) => {
    const [timeLeft, setTimeLeft] = useState<ITimeLeft>(getTimeLeft(new Date(), to));
    const { hours, minutes, seconds } = timeLeft;

    const isUrgent = useMemo(() => {
        return minutes < 10 && hours === 0;
    }, [minutes]);

    useEffect(() => {
        const decreaseTimeLeft = () => setTimeLeft(getTimeLeft(new Date(), to));
        const interval = setInterval(decreaseTimeLeft, 1000);

        return () => clearInterval(interval);
    }, [to]);

    const checkUndefined = (value: number | undefined) => {
        if (value === undefined) {
            return 0;
        }
        return value;
    };

    return (
        <div
            className={clsx(styles.counter, {
                [styles.counterUrgent]: isUrgent,
            })}
        >
            <span className={styles.digit}>{pad(checkUndefined(hours))}</span>
            <span className={styles.divider}>:</span>
            <span className={styles.digit}>{pad(checkUndefined(minutes))}</span>
            <span className={styles.divider}>:</span>
            <span className={styles.digit}>{pad(checkUndefined(seconds))}</span>
        </div>
    );
};

export { pad };
export default NavCounter;
