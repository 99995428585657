import React, { FC, useCallback, useState } from 'react';
import styles from './styled.module.scss';
import { Vehicles as VehicleTypes } from '~/models/vehicles';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import LicensePlate from '~/shared/license-plate/license-plate.component';
import { Modal } from '~/features/primitives/modal';
import Content from './content.component';
import Heading from '~/shared/heading/heading.component';
import { GA4CarInfoAdditional } from '~/libs/ga4';

interface IProps {
    isLoading?: boolean;
    carInfoData: VehicleTypes.IVehicleDetails;
}

const ElaboratedCarInfo: FC<IProps> = ({ isLoading, carInfoData }) => {
    const translate = useTranslations();
    const [isOpen, setOpen] = useState(false);

    const handleOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setOpen(false);
    }, []);

    return (
        <>
            <Modal
                position="right"
                open={isOpen}
                onOpenChange={handleOpenChange}
                meta={
                    <div className={styles.carInfoWrapper}>
                        <LicensePlate licensePlate={carInfoData?.licensePlate} />
                        <Heading tagName="h3">{translate('carInfoBanner.elaboratedCarInfoButton', 'Bilinformationer')}</Heading>
                    </div>
                }
            >
                <Content carInfoData={carInfoData} />
            </Modal>
            <div>
                <ButtonCircle
                    iconName="open-book"
                    tooltipText={translate('carInfoBanner.elaboratedCarInfoButton', 'Bilinformationer')}
                    onClick={() => {
                        GA4CarInfoAdditional('Bilinformationer');
                        setOpen(true);
                    }}
                    fetching={isLoading}
                />
            </div>
        </>
    );
};

export default ElaboratedCarInfo;
