import { IWithClassName } from '~/models/dev';
import ButtonAnimated, { ButtonAnimatedTypes } from '~/shared/buttons/button-animated/button-animated.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import styles from './styled.module.scss';

interface IExpandCollapseButtonProps extends IWithClassName {
    onClick: (isOpen: boolean) => void;
    isOpen: boolean;
}

export default function ExpandCollapseButton({ onClick, isOpen, className }: IExpandCollapseButtonProps) {
    const translate = useTranslations();

    const handleClick = () => onClick(!isOpen);
    const iconProps = {
        name: 'car',
    };

    const activeText = translate('common.hide', 'skjul');
    const inactiveText = translate('common.show', 'vis');
    const buttonText = isOpen
        ? translate('carInfoBanner.showLessInfo', 'Fæerre bilinformationer')
        : translate('carInfoBanner.showMoreInfo', 'Flere bilinformationer');

    const buttonAnimatedProps = {
        className: styles.button,
        onClick: handleClick,
        icon: iconProps,
        active: isOpen,
        text: buttonText,
        type: ButtonAnimatedTypes.thick,
        activeText,
        inactiveText,
    };

    return (
        <div className={[styles.buttonWrapper, className].join(' ')}>
            <ButtonAnimated {...buttonAnimatedProps} />
        </div>
    );
}
