import { useRouter } from 'next/router';
import useUser from '~/libs/use-user';
import { authEndpoints } from '~/services/auth-endpoints';

export function useEndSession() {
    const { push } = useRouter();
    const { user } = useUser();

    const endSession = async () => {
        const params = new URLSearchParams({
            id_token_hint: user?.idToken as string,
            post_logout_redirect_uri: `${window.location.origin}/logout`,
        });

        await push(`${authEndpoints.endSession}?${params}`);
    };

    return { endSession };
}
