import React, { FC } from 'react';
import clsx from 'clsx';
import { IMenuGroup } from '../../navigation.d';
import { Link } from '~/shared/link';
import { CmsData } from '~/models/cms-data';

import styles from './table.module.scss';

const Table: FC<IMenuGroup> = ({ data }) =>
    data?.links.length ? (
        <div className={styles.table}>
            <div className={styles.tableRow}>
                {data?.headers?.map((header: string[], index: number) => (
                    <div key={`${header}-${index}`} className={clsx(styles.tableCell, styles.tableHead)}>
                        {header}
                    </div>
                ))}
            </div>

            {data?.links?.map(
                (link: CmsData.ITableLink, index: number) =>
                    link && (
                        <Link prefetch={false} href={link?.url} key={`${link?.title}-${index}`} className={styles.tableRow}>
                            <span className={styles.tableCell}>{link?.title}</span>

                            {link.additionalColumns?.map((column, columnIndex) => (
                                <span key={`${column}-${columnIndex}`} className={styles.tableCell}>
                                    {column}
                                </span>
                            ))}
                        </Link>
                    )
            )}
        </div>
    ) : null;

export default Table;
