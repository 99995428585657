import clsx from 'clsx';
import { FC } from 'react';
import { Svg } from '~/shared/svg';
import { Link } from '~/shared/link';
import { useStaticContent } from '~/libs/queries/bff';

import { IWithClassName } from '~/models/dev';
import styles from './logo.module.scss';

const Logo: FC<IWithClassName> = ({ className }) => {
    const { data: staticContent } = useStaticContent();

    return (
        <Link aria-label="FTZ logo" href={staticContent?.loggedInPageUrl || '/'} className={clsx(styles.link, className)}>
            <Svg name="ftz-shop-logo-multi-line" className={styles.logo} />
        </Link>
    );
};

export default Logo;
