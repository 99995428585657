import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { Vehicles } from '~/models/vehicles.d';
import useUser from '~/libs/use-user';
import { apiClient } from '~/services/api-client';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';

export function useLatestVehicles(limit = 50) {
    const { isLoggedIn, user } = useUser();

    const { vehicles, createUrl } = useEndpoints();
    const url = createUrl({
        endpoint: vehicles.latestVehicles,
        localeOption: LocaleOptions.path,
        query: { limit },
    });

    return useQuery<Vehicles.ILatestVehiclesOverview, Error>({
        queryKey: queryKeys.vehicle.latestVehicles(limit),
        queryFn: async ({ signal }) => {
            return apiClient.auth(user).get(url, { signal });
        },
        enabled: isLoggedIn,
    });
}
