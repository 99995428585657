import cx from 'clsx';
import { Bff } from '~/models/bff';
import styles from './standard-nav.module.scss';
import { StandardNavLink } from '~/features/navigation/standard-nav/components/standard-nav-link/standard-nav-link';

export type StandardNavProps = {
    links: Bff.ILink[];
    mode: Bff.DisplayMode;
};

export function StandardNav({ links, mode }: StandardNavProps) {
    return (
        <ul
            className={cx(styles.standardNav, styles.grid, {
                [styles.small]: mode === 'small',
            })}
        >
            {links.map((link) => (
                <StandardNavLink key={link.id} link={link} />
            ))}
        </ul>
    );
}
