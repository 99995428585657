import { LocaleOptions, useEndpoints } from '~/services/service-endpoint';
import { useLayoutState } from '~/context/layout.context';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '~/libs/queries/query-keys';
import { apiClient } from '~/services/api-client';
import useUser from '~/libs/use-user';

export function useVehicleInvoicesCount(licensePlateOrVin: string) {
    const { user, isLoggedIn } = useUser();
    const { selectedDepartment } = useLayoutState();
    const { locale } = useRouter();

    const { orders, createUrl } = useEndpoints();
    const url = createUrl({
        endpoint: `${orders.invoices}/by-vehicle/count`,
        localeOption: LocaleOptions.path,
        query: {
            licensePlateOrVin,
            departmentId: selectedDepartment?.id as number,
        },
    });

    return useQuery<number, Error>({
        queryKey: queryKeys.vehicle.vehicleInvoicesCount(locale, licensePlateOrVin, selectedDepartment?.id),
        queryFn: async () => {
            return apiClient.auth(user).get(url);
        },
        enabled: isLoggedIn && !!licensePlateOrVin,
    });
}
