import React, { FC } from 'react';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';
import styles from './search-button.module.scss';

interface IProps {
    isOpen?: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const SearchButton: FC<IProps> = ({ isOpen, onClick }) => {
    const translate = useTranslations();

    return (
        <button
            onClick={onClick}
            aria-label={translate('search.button', 'Søg')}
            type="submit"
            className={isOpen ? styles.buttonInputFocus : styles.buttonInputBlur}
        >
            <Svg thick name="search" className={styles.icon} />
            <span className={styles.text}>{translate('search.button', 'Søg')}</span>
        </button>
    );
};

export default SearchButton;
