import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { addErrorToast, useLayoutDispatch } from '~/context/layout.context';
import { GA4CarInfoAdditional } from '~/libs/ga4';
import { useFeatureToggle, useFeatures } from '~/libs/queries/bff';
import { Vehicles as VehicleTypes } from '~/models/vehicles';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import DropdownContent, { DropdownItem } from '~/shared/dropdown-content/dropdown-content.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Svg } from '~/shared/svg';
import Text from '~/shared/text/text.component';
import WindshieldExchange from '../windshield-exchange/windshield-exchange.component';
import styles from './styled.module.scss';
import { Modal } from '~/features/primitives/modal';

interface IProps {
    links?: VehicleTypes.IVehicleDetailsLinks;
    licensePlate?: VehicleTypes.ILicensePlate;
}

export default function ServiceRequestLinks({ links, licensePlate }: IProps) {
    const translate = useTranslations();

    const router = useRouter();
    const [controlledVisible, setControlledVisible] = useState(false);
    const [modal, setModal] = useState<JSX.Element | null>(null);
    const dispatch = useLayoutDispatch();

    useEffect(() => {
        const handleKeyDown = ({ key }: KeyboardEvent) => {
            if (key === 'Escape') {
                setControlledVisible(false);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const replacementEngineHandler = () => {
        if (links?.replacementEngineRequestLink) {
            router.push(links.replacementEngineRequestLink);
            setControlledVisible(false);
        } else dispatch(addErrorToast(translate('common.somethingWentWrong', 'Der gik noget galt.')));
    };

    const oeSparePartsRequestHandler = () => {
        if (links?.oeSparePartsRequestLink) {
            router.push(links.oeSparePartsRequestLink);
            setControlledVisible(false);
        } else dispatch(addErrorToast(translate('common.somethingWentWrong', 'Der gik noget galt.')));
    };

    const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
        trigger: 'click',
        placement: 'left-start',
        visible: controlledVisible,
        onVisibleChange: setControlledVisible,
    });

    const { data: features } = useFeatures();
    const { shouldShowFeature } = useFeatureToggle();
    const showOeSparePartsRequestLink = links?.oeSparePartsRequestLink && shouldShowFeature(features?.carBanner?.oeSparePartsRequest);

    // add check for existing links here
    const shouldRender = showOeSparePartsRequestLink || links?.chassisSupportRequestLink || links?.supportLink;

    const handleOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setModal(null);
    }, []);

    if (!shouldRender) {
        return null;
    }

    return (
        <div>
            {visible && (
                <DropdownContent
                    title={translate('carInfoBanner.request', 'Forespørgsel')}
                    onClose={() => setControlledVisible(false)}
                    ref={setTooltipRef}
                    {...getTooltipProps()}
                >
                    {links?.supportLink && (
                        <DropdownItem
                            className={styles.item}
                            onClick={() => {
                                setControlledVisible(false);
                                window.open(links?.supportLink, '_blank');
                            }}
                        >
                            <div className={styles.linkItem}>
                                <Svg className={styles.iconStroke} name="technical-support" />
                                <Text textStyle="bodySmall" color="primaryColor">
                                    {translate('carInfoBanner.technicalSupport', 'Bilteknisk support')}
                                </Text>
                            </div>
                        </DropdownItem>
                    )}

                    {showOeSparePartsRequestLink && (
                        <DropdownItem
                            className={styles.item}
                            onClick={() => {
                                setControlledVisible(false);
                                oeSparePartsRequestHandler();
                            }}
                        >
                            <div className={styles.linkItem}>
                                <Svg className={styles.iconStroke} name="oe-badge" />
                                <Text textStyle="bodySmall" color="primaryColor">
                                    {translate('oeSpareParts.oeRequest', 'OE Forespørgsel')}
                                </Text>
                            </div>
                        </DropdownItem>
                    )}

                    {links?.chassisSupportRequestLink && (
                        <DropdownItem
                            className={styles.item}
                            onClick={() => {
                                setControlledVisible(false);
                                window.open(links?.chassisSupportRequestLink, '_blank');
                            }}
                        >
                            <div className={styles.linkItem}>
                                <Svg thick className={styles.iconStroke} name="car-body" />
                                <Text textStyle="bodySmall" color="primaryColor">
                                    {translate('carInfoBanner.chassisSupportRequestLink', 'Karrosseriteknisk Support')}
                                </Text>
                            </div>
                        </DropdownItem>
                    )}
                    {/*TODO: Re-enable after go from business*/}
                    <DropdownItem
                        className={styles.item}
                        onClick={() => {
                            setControlledVisible(false);
                            setModal(<WindshieldExchange license={licensePlate} setModal={setModal} />);
                            setControlledVisible(false);
                        }}
                    >
                        <div className={styles.linkItem}>
                            <Svg thick className={styles.iconStroke} name="windshield" />
                            <Text textStyle="bodySmall" color="primaryColor">
                                {translate('internalDashboard.changeWindshield', 'Rudeudskiftning')}
                            </Text>
                        </div>
                    </DropdownItem>

                    {links?.replacementEngineRequestLink && (
                        <DropdownItem
                            className={styles.item}
                            onClick={() => {
                                setControlledVisible(false);
                                replacementEngineHandler();
                            }}
                        >
                            <div className={styles.linkItem}>
                                <Svg name="engine" className={styles.iconStroke} />
                                <Text textStyle="bodySmall" color="primaryColor">
                                    {translate('carInfoBanner.engineReplacement', 'Forespørgsel på ombytningsmotor')}
                                </Text>
                            </div>
                        </DropdownItem>
                    )}
                </DropdownContent>
            )}
            <button
                onClick={() => {
                    GA4CarInfoAdditional('Forespørgsel');
                }}
                ref={setTriggerRef}
                type="button"
                className={styles.trigger}
            >
                <ButtonCircle isSpan iconName="request" tooltipText={translate('carInfoBanner.request', 'Forespørgsel')} />
            </button>
            <Modal position="right" onOpenChange={handleOpenChange} open={modal !== null}>
                {modal}
            </Modal>
        </div>
    );
}
