import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import styles from '~/shared/dam-calculator/calculator/calculator-dam.module.scss';
import DatePicker from '~/shared/form-elements/date-picker/date-picker.component';
import Button from '~/shared/buttons/button/button.component';
import Loader from '~/shared/loader/loader.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import clsx from 'clsx';
import { useDamCalculatorNumber } from '~/libs/queries/bff/hooks/use-dam-calculator-number';

interface ICalcProps {
    date: string;
}

const DamCalculatorForDam = () => {
    const translate = useTranslations();
    const [dataDate, setDataDate] = useState<string>('');
    const {
        control,
        handleSubmit,
        formState: { errors, isDirty },
    } = useForm<ICalcProps>();

    const { isError, isLoading, data, refetch } = useDamCalculatorNumber(dataDate);

    const onSubmit = (data: ICalcProps) => {
        setDataDate(data.date);
    };

    useEffect(() => {
        if (dataDate) {
            refetch();
        }
    }, [dataDate]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.inputWrapper}>
                <p className={styles.inputText}>{translate('damCalculator.enterDate', 'Indtast dato her:')}</p>
                <div className={styles.datePicker}>
                    <Controller
                        name="date"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => {
                            const validValue = value ? new Date(value).toISOString() : new Date().toISOString();

                            return (
                                <DatePicker
                                    value={validValue}
                                    onChange={onChange}
                                    className={clsx(styles.inputDate, { [styles.withError]: !!errors.date || isError })}
                                />
                            );
                        }}
                    />
                </div>
                <Button type="submit" disabled={!isDirty}>
                    {translate('damCalculator.search', 'Søg')}
                </Button>
                {isLoading ? <Loader size={'25px'} /> : null}
            </div>
            <p>
                {translate('damCalculator.carDamWasProducedOn', 'Bilen DAM/PR nummer er: ')}
                <b>{isLoading ? null : data?.number}</b>
            </p>
        </form>
    );
};

export default DamCalculatorForDam;
