import { IWithClassName } from '~/models/dev';
import Skeleton from '~/shared/skeleton';
import Text from '~/shared/text/text.component';

interface IBaseCarInfoProps extends IWithClassName {
    manufacturerName?: string;
    modelName?: string;
    modelTypeName?: string;
    loading?: boolean;
    typeYearFrom: string;
    typeYearTo: string;
    vehicleTypeEngineCodes: string[];
}

export default function BaseCarInfo({
    manufacturerName,
    modelName,
    modelTypeName,
    loading = false,
    className,
    typeYearFrom,
    typeYearTo,
    vehicleTypeEngineCodes,
}: IBaseCarInfoProps) {
    if (loading) {
        return <Skeleton isDark style={{ height: '16px', width: '200px', maxWidth: '100%' }} />;
    }

    const yearFromTo = typeYearFrom || typeYearTo ? `(${typeYearFrom || ''} - ${typeYearTo || ''})` : '';

    const engineCodesText = vehicleTypeEngineCodes?.length ? `, ${vehicleTypeEngineCodes?.join(', ')}` : '';

    const bylineText = `${modelTypeName} ${yearFromTo}${engineCodesText}`;

    return (
        <div className={className}>
            <Text overflowEllipsis color="primaryColor" title={`${manufacturerName} ${modelName}`}>
                {manufacturerName} {modelName}
            </Text>
            {modelTypeName && (
                <Text textStyle="bodyExtraSmall" overflowEllipsis title={bylineText}>
                    {bylineText}
                </Text>
            )}
        </div>
    );
}
