import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLayoutState } from '~/context/layout.context';
import useImpersonateMode from '~/services/user/use-impersonate-mode';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { Modal } from '~/features/primitives/modal';
import ModalContent from './modal-content/modal-content.component';
import { useCurrentBasket, useLoadSavedBasket, useLoadSavedBasketHandlers } from '~/libs/queries/basket';

const SwitchCustomerPrompt: FC = () => {
    const translate = useTranslations();

    const { customerOrBasketTransfer } = useLayoutState();
    const { activate } = useImpersonateMode();

    const { data: currentBasket } = useCurrentBasket();
    const { mutateAsync: loadBasketAsync } = useLoadSavedBasket();

    const { handleSuccess: handleLoadBasketSuccess, handleError: handleLoadBasketError } = useLoadSavedBasketHandlers();

    const [isOpen, setOpen] = useState(false);

    const isEmptyBasket = !currentBasket?.totalItemsCount;

    const startTransfer = async () => {
        const { basketId, customerId, callBack } = customerOrBasketTransfer ?? {};

        if (!isEmptyBasket) {
            setOpen(true);

            return;
        }

        if (customerId) {
            if (!basketId) {
                await activate(customerId, currentBasket?.id);
            } else {
                // Impersonate and transfer current basket
                await activate(customerId);
                await loadBasketAsync(
                    {
                        basketId,
                    },
                    {
                        onError: handleLoadBasketError,
                        onSuccess: handleLoadBasketSuccess,
                    },
                );
            }
        }

        callBack?.();
    };

    const [focus, setFocus] = useState(false);

    useEffect(() => {
        if (customerOrBasketTransfer) {
            startTransfer();
            setFocus(true);
        } else {
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerOrBasketTransfer]);

    const handleOpenChange = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setOpen(false);
    }, []);

    return (
        <div>
            <Modal
                position="right"
                open={isOpen}
                onOpenChange={handleOpenChange}
                title={translate('impersonate.youHaveOpenBasket', 'Du har en åben kurv')}
            >
                <ModalContent closeModal={() => setOpen(false)} focus={focus} />
            </Modal>
        </div>
    );
};

export default SwitchCustomerPrompt;
