import { Vehicles as VehicleTypes } from '~/models/vehicles';
import useTranslations from '~/shared/hooks/use-translations.hook';
import CarInfoItem from './car-info-item/car-info-item.component';

interface ICarInfoCollapsedProps {
    isLoading?: boolean;
    carInfoData?: VehicleTypes.IVehicleDetails;
    modelTypeId?: number;
}

export default function CarInfoBoxCollapsed({ isLoading, carInfoData, modelTypeId }: ICarInfoCollapsedProps) {
    const translate = useTranslations();

    return (
        <>
            <CarInfoItem className="hideXXLUp" loading={isLoading} title={translate('carInfoBanner.vin', 'Stelnr.')}>
                {carInfoData?.vin ?? '-'}
            </CarInfoItem>

            <CarInfoItem className="hideXLUp" loading={isLoading} title={translate('carInfoBanner.firstRegistrationDate', '1. reg.')}>
                {carInfoData?.firstRegistration}
            </CarInfoItem>

            {carInfoData?.nextApprovalDate && (
                <CarInfoItem className="hideXLUp" loading={isLoading} title={translate('carInfoBanner.lastApprovalDate', 'sidste syn')}>
                    {carInfoData?.nextApprovalDate}
                </CarInfoItem>
            )}

            <CarInfoItem className="hideXLUp" loading={isLoading} title={translate('carInfoBanner.engineCode', 'Motortype')}>
                {carInfoData?.engineType} / {carInfoData?.engineCode}
            </CarInfoItem>

            <CarInfoItem loading={isLoading} title={translate('carInfoBanner.modelId', 'Type nr.')}>
                {modelTypeId}
            </CarInfoItem>

            <CarInfoItem loading={isLoading} title={translate('carInfoBanner.kwHk', 'KW / HK')}>
                {`${carInfoData?.engineKw ?? '-'} / ${carInfoData?.enginePs ?? '-'}`}
            </CarInfoItem>

            <CarInfoItem loading={isLoading} title={translate('carInfoBanner.typeApproval', 'Typegodkendelse')}>
                {carInfoData?.typeApproval}
            </CarInfoItem>

            <CarInfoItem loading={isLoading} title={translate('carInfoBanner.propellant', 'Drivmiddel')}>
                {carInfoData?.engineFuel}
            </CarInfoItem>
            <CarInfoItem loading={isLoading} title={translate('carInfoBanner.euEnvironmentClass', 'EU Miljøklasse')}>
                {carInfoData?.euroEnvironmentClass}
            </CarInfoItem>
            <CarInfoItem loading={isLoading} title={translate('carInfoBanner.transmissionType', 'Gearkasse')}>
                {carInfoData?.transmissionType}
            </CarInfoItem>
        </>
    );
}
