import { Bff } from '~/models/bff';
import styles from './standard-nav-sub.module.scss';
import { CMSLink } from '~/shared/link';
import React, { useState } from 'react';
import cx from 'clsx';
import { MediaQuery } from '~/shared/media-query-detect';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { MainNavBackButton } from '~/features/navigation/main-nav/components/main-nav-back-button/main-nav-back-button.component';
import { useNavStore } from '~/features/navigation/shared/store';

export type StandardNavSubProps = {
    parent: Bff.ILink;
    level?: number;
};

const VISIBLE_ITEMS = 5;

export function StandardNavSub({ parent, level = 0 }: StandardNavSubProps) {
    const translate = useTranslations();

    const { isVisible, remove } = useNavStore((state) => ({
        isVisible: state.path.includes(parent.id),
        remove: state.remove,
    }));

    const [open, setOpen] = useState(false);

    const handleExpandClick = () => {
        setOpen((prev) => !prev);
    };

    const handleBackButtonClick = () => {
        remove(parent.id);
    };

    const { subLinks = [] } = parent;

    const isCollapsible = subLinks.length > VISIBLE_ITEMS;
    const isNested = level !== 0;

    return (
        <div
            className={cx(styles.standardNavSubContainer, {
                [styles.nested]: isNested,
                [styles.visible]: isVisible,
            })}
        >
            <MainNavBackButton className={styles.standardNavSubBackButton} onClick={handleBackButtonClick} title={parent.title} />
            <ul
                className={cx(styles.standardNavSub, {
                    [styles.collapsible]: isCollapsible,
                    [styles.open]: !isCollapsible || open,
                })}
            >
                {subLinks.map((item) => (
                    <StandardNavSubLink key={item.id} link={item} level={level} />
                ))}
            </ul>
            {isCollapsible ? (
                <button className={cx(styles.standardNavSubExpander)} onClick={handleExpandClick}>
                    {!open ? translate('common.showAll', 'Vis alle') : translate('common.hide', 'Skjul')}
                </button>
            ) : null}
        </div>
    );
}

export type StandardNavSubLinkProps = {
    link: Bff.ILink;
    level: number;
};

export function StandardNavSubLink({ link, level }: StandardNavSubLinkProps) {
    const { close, reset, add } = useNavStore((state) => ({ close: state.close, reset: state.reset, add: state.add }));

    const handleClick = () => {
        if (window.matchMedia(MediaQuery.Desktop).matches || !link.subLinks?.length) {
            reset();
            close();
            return;
        }

        add(link.id);
        return;
    };

    return (
        <li key={link.id}>
            <CMSLink prefetch={false} link={link} className={styles.standardNavSubLink} onClick={handleClick}>
                {link.title}
            </CMSLink>
            {link.subLinks?.length ? <StandardNavSub parent={link} level={level + 1} /> : null}
        </li>
    );
}
