import { isWindows } from '~/helpers';
import { GA4CarInfoAdditional } from '~/libs/ga4';
import { Vehicles as VehicleTypes } from '~/models/vehicles';
import ErrorReportButton from '~/page-elements/error-report/error-report-button.component';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import { Collapse } from '~/shared/collapse';
import DamCalculator from '~/shared/dam-calculator/dam-calculator.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import TireCalculator from '~/shared/tire-calculator/tire-calculator.component';
import styles from '../../styled.module.scss';
import CarInfoBoxCollapsed from '../car-info-box/car-info-box-collapsed.component';
import ElaboratedCarInfo from '../elaborated-car-info/elaborated-car-info.component';
import ServiceRequestLinks from '../service-request-links/service-request-links.component';
import VehicleInvoices from '../vehicle-invoices/vehicle-invoices.component';

interface ICarCollapsedInfoBannerProps {
    isOpen?: boolean;
    links?: VehicleTypes.IVehicleDetailsLinks;
    isLoading?: boolean;
    carInfoData: VehicleTypes.IVehicleDetails;
    handleCLickVehCareOil?: () => void;
    handleClickAutoFrontal?: () => void;
    handleHaynesProClick?: () => void;
    handleNFLinkClick?: () => void;
    licensePlate?: VehicleTypes.ILicensePlate;
    vin?: string;
    modelTypeId?: number;
    vehicleInvoicesCount: number;
    loading: boolean;
    licensePlateOrVin: string;
}

export const CollapsedInfoBanner = ({
    carInfoData,
    links,
    isLoading,
    isOpen,
    handleCLickVehCareOil,
    handleClickAutoFrontal,
    handleHaynesProClick,
    handleNFLinkClick,
    licensePlate,
    vin,
    modelTypeId,
    vehicleInvoicesCount,
    loading,
    licensePlateOrVin,
}: ICarCollapsedInfoBannerProps) => {
    const translate = useTranslations();
    return (
        <Collapse isOpen={isOpen} duration={0.5} ease={[0.55, 0, 0, 1]}>
            <div className={styles.collapseContent}>
                <div className={styles.collapseContentInner}>
                    <div className={styles.bannerDrawerAttrList}>
                        <CarInfoBoxCollapsed isLoading={isLoading} carInfoData={carInfoData} modelTypeId={modelTypeId} />
                    </div>
                    <div className={styles.bannerDrawerAttrList}>
                        {isWindows && links?.autoFrontalLink && (
                            <ButtonCircle
                                tooltipClassName="hideMdUp"
                                tooltipText={translate('carInfoBanner.carData', 'Autodata')}
                                fetching={isLoading}
                                iconName="car"
                                onClick={handleClickAutoFrontal}
                            />
                        )}
                        {links?.haynesLink && (
                            <ButtonCircle
                                tooltipClassName="hideMdUp"
                                tooltipText={translate('carInfoBanner.haynesPro', 'Haynes Pro')}
                                fillIcon
                                fetching={isLoading}
                                iconName="haynesPro"
                                onClick={handleHaynesProClick}
                            />
                        )}

                        {links?.nfDataLink && (
                            <ButtonCircle
                                tooltipClassName="hideMdUp"
                                fillIcon
                                fetching={isLoading}
                                iconName="nf-data"
                                tooltipText={translate('carInfoBanner.nFData', 'NF-Data')}
                                onClick={handleNFLinkClick}
                            />
                        )}

                        {links?.vantageDashboard && (
                            <ButtonCircle
                                tooltipClassName="hideMdUp"
                                tooltipText={translate('carInfoBanner.elf', 'Elf')}
                                iconName="drop"
                                onClick={handleCLickVehCareOil}
                                fetching={isLoading}
                                target="_blank"
                            />
                        )}

                        <ServiceRequestLinks links={links} licensePlate={licensePlate} />
                        <ElaboratedCarInfo isLoading={isLoading} carInfoData={carInfoData} />

                        {/* disable Bosch ESI button until BE has new Bosch ESI implementation ready  */}
                        {/*{carInfoData?.links?.showEsiButton && <BoschESI modelTypeId={carInfoData?.modelTypeId} />}*/}

                        {vehicleInvoicesCount > 0 && (
                            // TODO: @Lasse cmsCarId={} wsa originally not passed to VehicleInvoices
                            <VehicleInvoices
                                className="hideMdUp"
                                licensePlate={licensePlate}
                                vin={vin as string}
                                vehicleInvoicesCount={vehicleInvoicesCount}
                                isLoading={loading}
                                licensePlateOrVin={licensePlateOrVin}
                                cmsCarId={'cmsCarId'}
                            />
                        )}

                        {links?.inspectionReports && (
                            <ButtonCircle
                                fetching={isLoading}
                                iconName="crown"
                                tooltipText={translate('carInfoBanner.seeInspectionReports', 'Synsrapporter')}
                                onClick={() => {
                                    window.open(links?.inspectionReports, '_blank');
                                }}
                            />
                        )}

                        {/* {links?.vantageDashboard && (
            <ButtonCircle
              fillIcon
              iconName="olie"
              tooltipText={translate('carInfoBanner.vehcare', 'Vehcare')}
              onClick={handleCLickVehCareOil}
              fetching={isLoading}
            />
          )} */}
                        {carInfoData?.links?.showDamCalculatorButton && <DamCalculator />}
                        <TireCalculator tooltipText carInfoData={carInfoData} />

                        <ErrorReportButton
                            onCLick={() => {
                                GA4CarInfoAdditional('Meld fejl');
                            }}
                            data={{
                                carId: modelTypeId?.toString(),
                                licensePlateNumber: licensePlate?.number,
                            }}
                        />
                    </div>
                </div>
            </div>
        </Collapse>
    );
};
