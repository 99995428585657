import React from 'react';
import cx from 'clsx';
import { Link } from '~/shared/link';
import styles from './nav-link.module.scss';

export type NavLinkAnchorSize = 'small' | 'large';

export type NavLinkAnchorProps = {
    size?: NavLinkAnchorSize;
    href?: string;
    className?: string;
    target?: React.HTMLAttributeAnchorTarget;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    children?: React.ReactNode | React.ReactNode[] | null;
};

export function NavLinkAnchor({ href, size = 'small', target, onClick, children, className }: NavLinkAnchorProps) {
    return (
        <Link
            href={href}
            target={target}
            onClick={onClick}
            className={cx(styles.navLinkAnchor, className, {
                [styles.small]: size === 'small',
                [styles.large]: size === 'large',
            })}
        >
            {children}
        </Link>
    );
}

export type NavLinkProps = {
    className?: string;
    children?: React.ReactNode | React.ReactNode[] | null;
};

export function NavLink({ children, className }: NavLinkProps) {
    return <li className={cx(styles.navLink, className)}>{children}</li>;
}
