import React, { useCallback, useState } from 'react';
import { Modal } from '~/features/primitives/modal';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import Calculator from '~/shared/dam-calculator/calculator/calculator-dam.component';
import styles from './calculator-dam.module.scss';
import useTranslations from '~/shared/hooks/use-translations.hook';

const DamCalculator = () => {
    const [isShown, setIsShown] = useState(false);
    const translate = useTranslations();

    const handleChangeOpen = useCallback((open: boolean) => {
        if (open) {
            return;
        }

        setIsShown(() => false);
    }, []);

    return (
        <>
            <Modal
                className={styles.modalWrapper}
                position="center"
                title={translate('damCalculator.damPrCalculates', 'DAM/PR beregner')}
                onOpenChange={handleChangeOpen}
                open={isShown}
            >
                <Calculator />
            </Modal>
            <ButtonCircle
                tooltipText={translate('damCalculator.icon', 'DAM/PR beregner')}
                onClick={() => setIsShown(() => true)}
                iconName={'dam'}
                iconClassName={styles.icon}
                fillIcon
            />
        </>
    );
};

export default DamCalculator;
