import { useEffect } from 'react';
import { addErrorToast, useLayoutDispatch } from '~/context/layout.context';
import { GA4CarInspectionReminder } from '~/libs/ga4/ga4';
import useActiveUser from '~/libs/use-active-user';
import useUser from '~/libs/use-user';
import { Vehicles as VehicleTypes } from '~/models/vehicles';
import { useStoredLatestInspectionNotification } from '~/shared/hooks/use-stored-latest-inspection-notification';
import useTranslations from '~/shared/hooks/use-translations.hook';

const NINETY_DAYS_IN_MS = 30 * 24 * 60 * 60 * 1000;

export type UsePendingInspectionNotificationProps = {
    nextApprovalDateTime?: string;
    licensePlate?: VehicleTypes.ILicensePlate;
};

export function usePendingInspectionNotification({ nextApprovalDateTime, licensePlate = {} }: UsePendingInspectionNotificationProps) {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();

    const [latestInspectionNotification, setLatestInspectionNotification] = useStoredLatestInspectionNotification();
    const { isInternalUser } = useUser();
    const { activeProfile } = useActiveUser();
    const { employeeId, customer } = activeProfile || {};

    useEffect(() => {
        if (
            // we do nothing if the code is running in a blurred tab.
            // this is because of the local storage hook syncing across tabs,
            // making the code run in loops
            document.hidden ||
            !nextApprovalDateTime ||
            isInternalUser
        ) {
            return;
        }

        const today = new Date();
        const approvalDate = new Date(nextApprovalDateTime);
        const differenceBetweenApprovalAndToday = Number(approvalDate) - Number(today);
        const remainingMonths = differenceBetweenApprovalAndToday / NINETY_DAYS_IN_MS;

        const { number: carId } = licensePlate;

        if (latestInspectionNotification !== carId) {
            // we want to reset the stored license plate, if the current license plate doesn't match the stored one
            // reason for this being that we want to be able to remind the user if they need to view the car again.
            // the banner should only be shown once while browsing the car.
            setLatestInspectionNotification(undefined);
        }

        if (today > approvalDate || remainingMonths > 3 || latestInspectionNotification === carId) {
            // the notification should not be shown if these conditions are true:
            //   1. next approval date has been exceeded
            //   2. next approval date is more than 3 months in the future
            //   3. the notification has already been shown
            return;
        }

        dispatch(addErrorToast(translate('carInfoBanner.nextApprovalDateExpire', 'Din indtastede bil skal til syn indenfor 3 måneder'), carId));
        setLatestInspectionNotification(carId);
        GA4CarInspectionReminder(employeeId?.toString(), customer?.customerId?.toString());
    }, [
        nextApprovalDateTime,
        licensePlate,
        latestInspectionNotification,
        dispatch,
        translate,
        setLatestInspectionNotification,
        employeeId,
        customer?.customerId,
        isInternalUser,
    ]);
}
