import { useCombobox } from 'downshift';
import React, { FC, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useCurrentBasket } from '~/libs/queries/basket';
import useNavigationLayout from '~/context/use-navigation.context';
import { isBrowser } from '~/helpers';
import { CmsData } from '~/models/cms-data';
import ButtonCircle from '~/shared/buttons/button-circle/button-circle.component';
import Button from '~/shared/buttons/button/button.component';
import { useLayoutState } from '~/context/layout.context';
import { useStaticContent } from '~/libs/queries/bff';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { MediaQuery, useMediaQueryDetect } from '~/shared/media-query-detect';
import { IQuickSearchItem } from '../search-root.models.d';
import Countries from './countries/countries.component';
import styles from './input-field.module.scss';
import SearchButton from './search-button/search-button.component';
import SearchResults from './search-results/search-results.component';
import SearchTag, { VehicleTag } from './search-tag/search-tag.component';

interface IProps {
    selectedVehicle?: CmsData.IBaseVehicleInfo | null;
    removeSelectedVehicle: () => void;
    items: IQuickSearchItem[];
    parentValue: string;
    setParentInputValue: (val: string) => void;
    onSelectItem: (item: IQuickSearchItem) => void;
    onSubmit: React.FormEventHandler<HTMLFormElement>;
    onSearchButtonClick: React.MouseEventHandler<HTMLButtonElement>;
    isFetchingVehicle?: boolean;
    isFetchingProducts?: boolean;
    clearSearchHistory?: () => void;
    isError: boolean;
}

const InputField: FC<IProps> = ({
    setParentInputValue,
    onSubmit,
    onSearchButtonClick,
    items = [],
    onSelectItem,
    parentValue,
    isFetchingVehicle,
    isFetchingProducts,
    selectedVehicle,
    removeSelectedVehicle,
    clearSearchHistory,
    isError,
}) => {
    const translate = useTranslations();
    const { licensePlateCountry } = useLayoutState();
    const { data: staticContent } = useStaticContent();
    const { navState, setNavState } = useNavigationLayout();
    const { data: basket } = useCurrentBasket();
    const [showCountries, setShowCountries] = useState(false);
    const isPortable = useMediaQueryDetect(MediaQuery.MD);
    const inputElRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (navState?.searchIsOpen) {
            inputElRef?.current?.focus();
            openMenu();
        } else {
            inputElRef?.current?.blur();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navState?.searchIsOpen]);

    const handleSelectItem = (item: IQuickSearchItem) => {
        inputElRef?.current?.blur();
        onSelectItem(item);
        if (navState?.searchIsOpen) {
            setNavState({ searchIsOpen: false });
        }
    };

    const path = isBrowser ? window.location.pathname : null;

    useEffect(() => {
        if (!isPortable) {
            inputElRef?.current?.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);

    useEffect(() => {
        if (basket?.isImpersonation) {
            inputElRef?.current?.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basket?.customerName]);

    const { isOpen, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps, openMenu, closeMenu } = useCombobox({
        id: 'search-combobox',
        items,
        stateReducer: (state, actionAndChanges) => {
            const { type, changes } = actionAndChanges;

            switch (type) {
                case useCombobox.stateChangeTypes.ItemClick:
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                    if (changes.selectedItem) {
                        handleSelectItem(changes.selectedItem);
                        closeMenu();
                        return {
                            ...changes,
                            ...(changes.selectedItem && {
                                parentValue: changes.selectedItem.text || '',
                            }),
                        };
                    }
                    return state; // Return the unchanged state when selectedItem is null or undefined
                default:
                    return changes;
            }
        },
    });

    const showSuggestions = (isOpen && items.length > 0) || isFetchingProducts || isFetchingVehicle;

    return (
        <div className={isOpen ? styles.rootFocus : styles.root}>
            <div {...getComboboxProps()} className={styles.inputWrapper}>
                <form
                    className={clsx(styles.form, {
                        [styles.isSuggestionsOpen]: showSuggestions || showCountries,
                        [styles.onFocus]: isOpen || showCountries,
                        [styles.onBlur]: !isOpen && !showCountries,
                    })}
                    onSubmit={(e) => {
                        closeMenu();
                        if (navState?.searchIsOpen) {
                            setNavState({ searchIsOpen: false });
                        }
                        onSubmit(e);
                    }}
                >
                    {selectedVehicle && (
                        <SearchTag onClose={removeSelectedVehicle}>
                            <VehicleTag vehicle={selectedVehicle} />
                        </SearchTag>
                    )}
                    <input
                        aria-label={
                            selectedVehicle
                                ? translate('search.placeholderSearchWord', 'Tilføj et søgeord')
                                : translate('search.placeholder', 'Søg på registrerings-, vare-, FTZ-numre…')
                        }
                        type="search"
                        placeholder={
                            selectedVehicle
                                ? translate('search.placeholderSearchWord', 'Tilføj et søgeord')
                                : translate('search.placeholder', 'Søg på registrerings-, vare-, FTZ-numre…')
                        }
                        {...getInputProps({
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setParentInputValue(e.target.value),
                            value: parentValue,
                            // onFocus: openMenu,
                            onClick: openMenu,
                            ref: inputElRef,
                        })}
                        className={styles.input}
                    />
                    {parentValue && (
                        <ButtonCircle
                            iconName="close"
                            buttonStyle="dark"
                            buttonSize="small"
                            onClick={() => {
                                setParentInputValue('');
                                inputElRef?.current?.focus();
                            }}
                        />
                    )}
                    {staticContent?.licensePlateLanguages?.length ? (
                        <Button
                            buttonSize="small"
                            buttonStyle="secondary"
                            onClick={() => {
                                if (isOpen) {
                                    closeMenu();
                                }
                                setShowCountries(!showCountries);
                            }}
                        >
                            {licensePlateCountry?.toUpperCase() || '-'}
                        </Button>
                    ) : null}
                </form>
                <SearchButton
                    isOpen={showSuggestions || showCountries}
                    onClick={(e) => {
                        if (navState?.searchIsOpen) {
                            setNavState({ searchIsOpen: false });
                        }
                        closeMenu();
                        onSearchButtonClick(e);
                    }}
                />
            </div>
            <SearchResults
                isError={isError}
                clearSearchHistory={clearSearchHistory}
                isFetchingVehicle={isFetchingVehicle}
                isFetchingProducts={isFetchingProducts}
                // isOpen={showSuggestions}
                items={items}
                parentValue={parentValue}
                getItemProps={getItemProps}
                getMenuProps={getMenuProps}
                highlightedIndex={highlightedIndex}
                className={clsx(styles.suggestions, {
                    [styles.isOpen]: showSuggestions,
                })}
            />
            <Countries
                isOpen={showCountries}
                close={() => setShowCountries(false)}
                className={clsx(styles.countries, {
                    [styles.isOpen]: showCountries,
                })}
            />
        </div>
    );
};

export default InputField;
